import { useState } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import axios from "../apis/axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function SearchTabs() {

    const navigate = useNavigate();

    const [hourlyArrival, setHourlyArrival] = useState(null);

    const [locationD, setLocationD] = useState(null);
    const [propertyTypeD, setPropertyTypeD] = useState(null);

    const [timeHr, setTimeHr] = useState("0:00");
    const [hoursHr, setHoursHr] = useState("3");
    const [locationHr, setLocationHr] = useState(null);
    const [propertyTypeHr, setPropertyTypeHr] = useState(null);
    const [allRooms, setAllRooms] = useState([]);
    const [payload, setPayload] = useState({});

    const [allCategories, setAllCategories] = useState([]);
    const [allStates, setAllStates] = useState([]);
    // const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // const [startDate, endDate] = dateRange;



    async function getAllCategories(){

        try{
            const categories = await axios.get("/categories");

            if (categories.status === 200) {
                const categoriesObj = categories.data.map((item) => {
                    return {value: item.id, label:  item.name}
                });
                setAllCategories(categoriesObj);
            }
            
        }catch(error){
            console.log(error);
        }

    } 
    
    async function getAllStates(){

        try{
            const states = await axios.get("/states");

            if (states.status === 200) {
                const statesObj = states.data.map((item) => {
                    return {value: item.id, label:  item.name}
                });
                setAllStates(statesObj);
            }
            
        }catch(error){
            console.log(error);
        }

    } 
    
    useEffect(() => {
        getAllCategories();
        getAllStates();
    }, []);

    function handleSubmit(event){
        event.preventDefault();

        if (event.target.elements?.submitBtnD){

            const start = new Date(startDate);
            const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
            const end = new Date(endDate);
            const endD = new Date(end.setDate(end.getDate()+1)).toISOString().split("T")[0]; 

            const criteria = {
                categoryId : propertyTypeD,
                stateId: locationD,
                start: startD,
                end: endD
            };
            navigate("/search", { state: criteria });

        }

        if (event.target.elements?.submitBtnHr){

            const start = new Date(startDate);
            const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
            
            const criteria = {
                categoryId : propertyTypeHr,
                stateId: locationHr,
                start: startD,
                end: startD,
                time: timeHr,
                hours: hoursHr,
            };

            navigate("/search", { state: criteria });
        }
		
	}


  return (
    <>
        <div className="container toggle-section px-5">
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active btn px-5 py-2 rounded-0" id="nav-daily-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Daily</button>
                    <button className="nav-link btn px-5 rounded-0" id="nav-hourly-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Hourly</button>
                </div>
            </nav>
            <div className="tab-content bg-grey p-4" id="nav-tabContent">
            <div className="tab-pane fade " id="nav-home" role="tabpanel" aria-labelledby="nav-hourly-tab">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 pb-3">
                            <label className="text-danger fw-bold">DESTINATIONS</label>
                            <ReactSelect name="location" options={allStates} onChange={(e) => { setLocationHr(e.value) }} placeholder="Destination..." className="mt-auto pt-2" required />
                        </div>
                        <div className="col pb-3">
                            <label className="text-danger fw-bold ">ACCOMODATION</label>  
                            <ReactSelect name="propertyType" options={allCategories} onChange={(e) => { setPropertyTypeHr(e.value) }} placeholder="Accomodation..." className="mt-auto pt-2" required />
                        </div>
                        <div className="col pb-3">
                            <label className="text-danger fw-bold">CHECK IN</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                className="form-control mt-2"
                                onChange={(update) => { 
                                    console.log(update);
                                    setHourlyArrival(update);
                                }}
                                minDate={new Date()}
                                selected={hourlyArrival}
                                placeholderText={"yyyy-mm-dd"}
                            />
                        </div>
                        <div className="col-md-1 pb-3">
                            <label className="text-danger fw-bold">HOURS</label>
                            <select name="" id="hoursInput" defaultValue={"3"}  onChange={(e) => { console.log(e); setHoursHr(e.target.value)}}  className="form-select mt-2">
                                <option value="3">3</option>
                                <option value="6">6</option>
                                <option value="9">9</option>
                                <option value="12">12</option>
                            </select>
                              
                        </div>
                        <div className="col-md-2 pb-3">
                            <label className="text-danger fw-bold">TIME</label>
                            <select name="" id="timeInput" defaultValue={"0:00"} onChange={(e) => { console.log(e); setTimeHr(e.target.value)}} className="form-select mt-2">
                                {
                                    (hoursHr == "12")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "9")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "6")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "3")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00 ">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                        <option value="19:00">19:00 pm</option>
                                        <option value="20:00">20:00 pm</option>
                                        <option value="21:00">21:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                            </select>
                        </div>
                          
                        <div className="col my-auto pb-3" align="center" >
                            <button type="submit"  name='submitBtnHr' className="btn btn-danger px-5 rounded-pill"><span className="fw-bolder fs-6">SEARCH</span></button>
                        </div>
                      </div>
                </form>
            </div>


                <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-daily-tab">
                    
                    <form onSubmit={handleSubmit}>
                        
                    <div className="row">

                        <div className="col-md-3 pb-3">
                        <label className="text-danger fw-bold">DESTINATIONS</label>
                        <ReactSelect name="locationD" id='locationD' options={allStates} onChange={(e) => { setLocationD(e.value) }} placeholder="Destination..." className="mt-auto pt-2" required />
                        </div>

                        <div className="col-md-2 pb-3">
                        <label className="text-danger fw-bold ">ACCOMODATION</label>  
                        <ReactSelect name="propertyTypeD" id='propertyTypeD' options={allCategories} onChange={(e) => { setPropertyTypeD(e.value) }} placeholder="Accomodation..." className="mt-auto pt-2" required />
                        </div>

                        <div className="col-md-4 pb-3">
                        <div className="row">
                            <div className="col">
                                <label className="text-danger fw-bold">CHECK IN</label>
                                <DatePicker
                                    selected={startDate}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control mt-2"
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={new Date()}
                                    placeholderText={"YYYY-MM-DD"}
                                />
                            </div>

                            <div className="col">
                                <label className="text-danger fw-bold">CHECK OUT</label>
                                <DatePicker
                                    selected={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control mt-2"
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText={"YYYY-MM-DD"}
                                />
                            </div>
                        </div>

                        {/* <DatePicker
                            dateFormat="yyyy-MM-dd"
                            className="form-control mt-2"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => { 
                                setDateRange(update);
                                console.log(update);
                            }}
                            minDate={new Date()}
                            placeholderText={"yyyy-mm-dd - yyyy-mm-dd"}
                        /> */}
                        </div>
                        
                        <div className="col my-auto" align="center" >
                            <button type="submit" name='submitBtnD' className="btn btn-danger px-5 rounded-pill"><span className="fw-bolder fs-6">SEARCH</span></button>
                        </div>

                    </div>

                    </form>


                </div>
            </div>
        </div>
    </>
  )
}

export default SearchTabs