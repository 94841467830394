import { useState } from "react";
import axios from "../apis/axios";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Contact() {

  const [error, setError] = useState();  
  const [success, setSuccess] = useState();  
  const [submit, setSubmit] = useState("Send Message");

  async function handleContacts(event){
    event.preventDefault();
    event.target.elements.submitBtn.disabled = true;
    setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
    const criteria = {
      firstName: event.target.elements.firstName.value,
      lastName: event.target.elements.lastName.value,
      email: event.target.elements.email.value,
      subject: event.target.elements.subject.value,
      message: event.target.elements.message.value
    };

    console.log(criteria);
    try{
        const response = await axios.post("/contacts",
        JSON.stringify(criteria), 
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

        if (response.status === 200) {
          setSuccess("Message sent successfully.");
          event.target.elements.submitBtn.disabled = false;
          setSubmit("Send Message");
          // window.location.reload(false);
          document.getElementById("contact-form").reset();
        }
        
    }catch(error){
        console.log(error);
    }

  } 

    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div className=" px-5 pt-5">
            <br /><br />
            <h2 className="pt-5 pb-5 text-white">Contact Us</h2>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col px-5">
                <form onSubmit={handleContacts} id="contact-form" className="px-5">

                    <p className="py-5  text-dark h2">Contact Information</p>
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>

                    <div className="row">
                        <div className="col pb-4">
                            <label for="firstname" className="text-dark">First name</label>
                            <input type="text" name="firstName" className="form-control form-text border-0 border-bottom" id="firstname" aria-describedby="typefname" required />
                        </div>
                        <div className="col">
                            <label for="lastname" className="text-dark">Last name</label>
                            <input type="text" name="lastName" id="lastname" className="form-control form-text border-0 border-bottom" aria-describedby="typelname" required />
                        </div>
                    </div>
                    <div className="pb-4">
                        <label for="email" className="text-dark">Email Address</label>
                        <input type="email" name="email" className="form-control form-text border-0 border-bottom" id="email" required />
                    </div>
                    <div className="pb-4">
                        <label for="subject" className="text-dark">Subject</label>
                        <input type="text" name="subject" id="subject" className="form-control form-text border-0 border-bottom" required />
                    </div>
                    <div className="pb-4">
                        <label for="message" className="text-dark">Description</label>
                        <textarea name="message" id="message" rows="2" className="form-control form-text border-0 border-bottom"></textarea>
                    </div>
                    <button type="submit" name="submitBtn" className="text-uppercase btn btn-danger rounded-pill"> {submit} </button>
                </form>
            </div>


            <div className="col p-5 py-5">
              <div className="row text-start py-5 pb-5 icon-margin">
                <div className="col"><i className="fa fa-instagram text-danger fa-2x"></i></div>
                <div className="col"><i className="fa fa-facebook text-danger fa-2x"></i></div>
                <div className="col"><i className="fa fa-twitter text-danger fa-2x"></i></div>
                <div className="col"><i className="fa fa-linkedin text-danger fa-2x"></i></div>
                <div className="col-md-5"></div>
              </div>

              <div className="text-dark fw-normal pb-4 fs-6">
                <i className="fa fa-map-marker fa-lg text-warning text-danger">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                3-9 Olu Kolesoho Street, Off Medical Road, Ikeja,Lagos.
              </div>

              <a href="tel:+234 90 999 909 72">
                <div className="text-dark fw-normal pb-4 fs-6">
                  <i className="fa fa-phone fa-lg text-warning text-danger">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                  +234 90 999 909 72
                </div>
              </a>

              <a href="mailto:info@hotbym.com">
                <div className="text-dark fw-normal pb-4 fs-6">
                  <i className="fa fa-envelope-o fa-lg text-warning text-danger">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                  info@hotbym.com
                </div>
              </a>
                
            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
  
  export default Contact;