import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SearchTabs from '../components/SearchTabs'
import Banner from '../components/slider/Banner'
import axios from '../apis/axios'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
// import 'react-owl-carousel2/src/owl.theme.default.css';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    items: 3,
    loop: true,
    nav: false,
    navText: ["<button class='btn btn-dark rounded-circle'> < </button>", "<button class='btn btn-dark rounded-circle'> > </button>"],
    autoplay: true,
    responsive:{
        0:{
            items:1,
            nav:true
        },
        600:{
            items:3,
            nav:false
        },
        1000:{
            items:3,
            nav:true,
            loop:false
        }
    },
    navContainer: ".nav-me"

};

function Home() {

    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [states, setStates] = useState([]);
    const [statesCriteria, setStatesCriteria] = useState([]);
    // const [states1, setStates1] = useState([]);
    // const [states2, setStates2] = useState([]);
    // const [states3, setStates3] = useState([]);
    // const [states4, setStates4] = useState([]);
    // const [states1Title, setStates1Title] = useState([]);
    // const [states1ID, setStates1ID] = useState([]);
    // const [states2Title, setStates2Title] = useState([]);
    // const [states2ID, setStates2ID] = useState([]);
    // const [states3Title, setStates3Title] = useState([]);
    // const [states3ID, setStates3ID] = useState([]);
    // const [states4Title, setStates4Title] = useState([]);
    // const [states4ID, setStates4ID] = useState([]);
    const [newProperties, setNewProperties] = useState([]);
    const propertiesImages = [];
    const categoriesImages = [];
    const statesImages = [];

    const [stateSlides, setStateSlides] = useState([]);
    let visiblePopularHotelsCount = 0;
    let visiblePopularCategoriesCount = 0;
    let visibleNewHotelsCount = 0;


    async function getPorpularProperties(){
        try{
            const properties = await axios.get("/properties");
            //console.log(properties.data);
            if (properties.status === 200) {

                const propertiesArray = await properties.data.map(async (item) => {

                    const images = await axios.post("/properties/images",
                    JSON.stringify({ propertyId: parseInt(item.id)}), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                    let imageResult = null;
                    if (images.data.length > 0){
                        imageResult = images.data[0].url;
                    }
                    propertiesImages.push(await {...item, imageResult});
                    return {...item, imageResult};

                });

                setProperties(await Promise.all(propertiesArray));
                setNewProperties(await Promise.all(propertiesArray.reverse()));
            }
            
        }catch(error){
            console.log(error);
        }

    }

    async function getPorpularCategories(){
        try{
            const categories = await axios.get("/categories");

            if (categories.status === 200) {

                const categoriesArray = await categories.data.map(async (item) => {

                    const images = await axios.post("/categories/images",
                    JSON.stringify({ categoryId: parseInt(item.id)}), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                    let imageResult = null;
                    if (images.data.length > 0){
                        imageResult = images.data[0].url;
                    }
                    categoriesImages.push(await {...item, imageResult});
                    return {...item, imageResult};

                });

                setCategories(await Promise.all(categoriesArray));

            }
            
        }catch(error){
            console.log(error);
        }

    }
    
    async function getPorpularStates(){
        try{
            const states = await axios.get("/states");

            if (states.status === 200) {

                const statesArray = await states.data.map(async (item) => {

                    const images = await axios.post("/states/images",
                    JSON.stringify({ stateId: parseInt(item.id)}), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                    let imageResult = null;
                    if (images.data.length > 0){
                        imageResult = images.data[0].url;
                    }
                    statesImages.push(await {...item, imageResult});
                    return {...item, imageResult};

                });

                const start = new Date();
                const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
                const end = new Date();
                const endD = new Date(end.setDate(end.getDate()+1)).toISOString().split("T")[0]; 
                const criteria = {
                    categoryId : 1,
                    start: startD,
                    end: endD
                };
                setStatesCriteria(criteria);

                setStates(await Promise.all(statesArray));

                const slides = statesImages.map((item, index) => {

                    if(index < 15){
                        if(item.imageResult != null){
                            return (
                                <>
                                    <div className="post-img px-5" >
                                        <img src={item.imageResult+"nth/0/"} alt="" title="card image" className="card-curves" />
                                        <Link to={"/search"} state={{...statesCriteria, stateId: item.id }}><h4 className='text-center py-3 text-danger text-uppercase fw-bold'>{item.name}</h4></Link>
                                    </div>
                                </>
                            )
                        }
                    }
                });

                setStateSlides(slides);
    
            }
            
        }catch(error){
            console.log(error);
        }

    }

    useEffect(() => {
        getPorpularProperties();
        getPorpularCategories();
        if(statesImages.length < 1){
            getPorpularStates();
        }
    }, []);

    return (
    <>
        <Header />
        <Banner />
        <SearchTabs />

        <div className="container-fluid bg-shade py-5">
            <div className="container py-5">
                <div className="row">

                    {
                        categories.map((item, index) => {

                            visiblePopularCategoriesCount += 1;
                            const start = new Date();
                            const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
                            const end = new Date();
                            const endD = new Date(end.setDate(end.getDate()+1)).toISOString().split("T")[0]; 

                            const criteria = {
                                categoryId : item.id,
                                stateId: 2,
                                start: startD,
                                end: endD
                            };

                            let imageUrl = item.imageResult;
                            if (imageUrl === null){
                                imageUrl = "/images/default.png";
                            }else{
                                imageUrl = item.imageResult+"nth/0/"
                            }

                            if(visiblePopularCategoriesCount < 5) {
                                return (
                                    <div className="col-md-3  col-sm-3 " key={item.id}>
                                        <Link to={"/search"} state={criteria}>
                                            <div className="card bg-transparent pt-5">
                                                <img src={imageUrl} alt="" className="card-curves" />
                                            </div>
                                            <div className='px-5'>
                                                <p className='fs-6 text-center fw-bold text-uppercase text-danger pt-2'>{item.name}</p> 
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                            
                        })
                    }
                </div>
            </div>
        </div>


        <div className="popular-hotel">
            <div className="container py-5">

                <h2 className="text-title text-center mt-5">Popular Hotels</h2>
                <p className="text-center">Something going on here.</p>

                <div className="row py-5">
                    { 
                        properties.map((item) => {
                            if(item.status == "active") {
                                visiblePopularHotelsCount += 1; 
                                let imageUrl = item.imageResult;
                                if (imageUrl === null){
                                    imageUrl = "/images/default.png";
                                }else{
                                    imageUrl = item.imageResult+"nth/0/"
                                }
                                if(visiblePopularHotelsCount < 5) {

                                    const sortedRooms =  item?.Rooms?.sort((a, b) => (a.price > b.price) ? 1 : -1)

                                    return (
                                        <div className="col-md-3 col-sm-3" key={item.id}>
                                            <Link to={"/property/"+ item.id} state={{}}>
                                                <div className="card border card-curves ">
                                                    <img src={imageUrl} alt="" title="card image" className="card-curves-top card-img" />
                                                    <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7 col-sm-8">
                                                            <Link to={"/property/"+ item.id}><h3 className="text-dark fs-6 fw-bold">{item.name}</h3></Link>
                                                        </div>
                                                        <div className="col-md-5 col-sm-4">
                                                            <h3 className="text-danger text-right fs-6" align="right"><b>₦</b>{sortedRooms[0]?.price || "0"}</h3>
                                                        </div>
                                                    </div>
                                                    <p className="card-text text-dark">{item.address}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
        </div>

        <div className="cheapest-hotel pb-5">
            <div className="container pt-1">

                <h2 className="text-title text-center mt-5">New Hotels</h2>
                <p className="text-center">Something going on here.</p>

                <div className="row py-5">

                    {
                        newProperties.map((item, index) => {
                            if(item.status == "active") {
                                visibleNewHotelsCount += 1;

                                let imageUrl = item.imageResult;
                                
                                if (imageUrl === null){
                                    imageUrl = "/images/default.png";
                                }else{
                                    imageUrl = item.imageResult+"nth/0/"
                                }

                                if(visibleNewHotelsCount < 5) {

                                    const sortedRooms =  item?.Rooms?.sort((a, b) => (a.price > b.price) ? 1 : -1)

                                    return (
                                        <div className="col-md-3 col-sm-3" key={item.id}>
                                            <Link to={"/property/"+ item.id}  state={{}}>
                                                <div className="card border card-curves ">
                                                    <img src={imageUrl} title="card image" alt='' className="card-curves-top card-img" />
                                                    <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7 col-sm-8">
                                                            <Link to={"/property/"+ item.id}><h3 className="text-dark fs-6 fw-bold">{item.name}</h3></Link>
                                                        </div>
                                                        <div className="col-md-5 col-sm-4">
                                                            <h3 className="text-danger fs-6 text-right" align="right"><b>₦</b>{sortedRooms[0]?.price || "0"}</h3>
                                                        </div>
                                                    </div>
                                                    <p className="card-text text-dark">{item.address}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            }
                        })
                    }

                </div>
            
            </div>
        </div>

        <div className="container py-5">

            <div className="container p-5">

                <div className="cta row rounded border">

                    <div className="col-md-3"></div>
                    <div className="col-md-8 p-5">
                        <div className="row py-4">
                            <div className='col-md-8'>
                                <p className="text-title h3 pt-5">
                                    Find Hotels &amp;<br /> Apartments  for your next trip.
                                </p>
                            </div>
                            <div className="col-md-4 mt-auto">
                                <Link to={"/search"} className='btn px-5 btn-dark center rounded-pill'>Explore</Link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div className="top-destination bg-shade">

           <div className="container py-5 px-5">
            <h2 className="text-center text-dark">Top Destination</h2>
            <p className="text-center">Something going on here.</p>

                <div className="row py-5">
                    <div className="col-md-12">
                {
                    (states.length > 0)
                    
                    ?(<>
                    <OwlCarousel options={options}>
                        {stateSlides}    
                    </OwlCarousel>
                    </>)
                    :(<></>)
                    }
                    <div className='nav-me d-flex justify-content-between'></div>
                    </div>

                </div>
            </div>

        </div>

        <Footer />
    </>
    )
}

export default Home