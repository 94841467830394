import { useState } from "react";
import axios from "../apis/axios";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Request() {

  // const [error, setError] = useState();  
  const [success, setSuccess] = useState();  
  const [submit, setSubmit] = useState("Make Request");
  const [formValues, setFormValues] = useState([{name : ""}])

  async function handleRequests(event){
    event.preventDefault();
    event.target.elements.submitBtn.disabled = true;
    setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
    const criteria = {
      companyName: event.target.elements.companyName.value,
      email: event.target.elements.email.value,
      guest: formValues,
      telephone: event.target.elements.telephone.value,
      location: event.target.elements.location.value,
      budget: event.target.elements.budget.value,
      checkin: event.target.elements.checkin.value,
      checkout: event.target.elements.checkout.value,
      additions: event.target.elements.additions.value
    };

    // console.log(criteria);

    try{
        const response = await axios.post("/requests",
        JSON.stringify(criteria), 
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

        if (response.status === 200) {
          // console.log(criteria);
          setSuccess("Message sent successfully.");
          event.target.elements.submitBtn.disabled = false;
          setSubmit("Make Request");
          window.location.reload(false);
        }
        
    }catch(error){
        event.target.elements.submitBtn.disabled = false;
        setSubmit("Make Request");
        console.log(error);
    }

  } 

  const handleChange = (i, e) => {
      const newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  
  const addFormFields = () => {
      setFormValues([...formValues, { name: "" }])
    }
  
  const removeFormFields = (i) => {
      const newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues)
  }

    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div className=" px-5 pt-5">
            <br /><br />
            <h2 className="pt-5 pb-5 text-white">Corporate Bookings</h2>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-md-7 px-5">
                <form onSubmit={handleRequests} className="px-5">

                    <p className="py-5  text-dark h2">Request Booking</p>
                    <span className="text-success small"><b>{success}</b></span>

                    <div className="row">
                      <div className="col pb-4">
                          <label for="firstname" className="text-dark">Company name :</label>
                          <input type="text" name="companyName" className="form-control form-text border-0 border-bottom" aria-describedby="typefname" required />
                      </div>
                    </div>
                    {formValues.map((element, index) => (
                      <div className="row" key={index}>
                        <div className="col pb-3">
                          <label  className="text-dark">Guest Name :</label>
                          <input type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} className="form-control form-text border-0 border-bottom" aria-describedby="typefname" required />
                        </div>

                        <div className="col-md-3 btn-box my-auto">
                        { 
                          index ? 
                            <button type="button" className="btn btn-sm btn-danger" onClick={() => removeFormFields(index)}> - </button> 
                          : null
                        }
                        </div>
                        
                      </div>
                      ))
                    }
                    <div className="button-section pb-4">
                      <button className="btn btn-sm btn-dark" type="button" onClick={() => addFormFields()}> + </button>
                    </div>

                    <div className="row">
                      <div className="pb-4 col">
                          <label className="text-dark">Telephone :</label>
                          <input type="text" name="telephone" id="subject" className="form-control form-text border-0 border-bottom" required />
                      </div>
                      <div className="pb-4 col">
                          <label className="text-dark">Email Address :</label>
                          <input type="email" name="email" className="form-control form-text border-0 border-bottom" required />
                      </div>
                    </div>

                    <div className="row">
                      <div className="pb-4 col">
                          <label className="text-dark">Location :</label>
                          <input type="text" name="location" className="form-control form-text border-0 border-bottom" required />
                      </div>
                      <div className="pb-4 col">
                          <label className="text-dark">Budget :</label>
                          <input type="text" name="budget" className="form-control form-text border-0 border-bottom" required />
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="pb-4 col">
                          <label className="text-dark">Check-in :</label>
                          <input type="date" name="checkin" className="form-control form-text border-0 border-bottom" required />
                      </div>
                      <div className="pb-4 col">
                          <label className="text-dark">Check-out :</label>
                          <input type="date" name="checkout" className="form-control form-text border-0 border-bottom" required />
                      </div>
                    </div>
                     
                    <div className="pb-4">
                        <label for="message" className="text-dark">Additional Information :</label>
                        <textarea name="additions" id="message" rows="2" className="form-control form-text border-0 border-bottom"></textarea>
                    </div>
                    <button type="submit" name="submitBtn" className="text-uppercase btn btn-danger rounded-pill"> {submit} </button>
                </form>
            </div>

            <div className="col p-5 py-5"></div>

          </div>
        </div>

        <Footer />
      </>
    )
  }
  
  export default Request;