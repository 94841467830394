import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

function TermsAndConditions() {
    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>

            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">Terms And Conditions</h2>
            </div>

        </div>

        <div className="container p-5">
            <p className="fs-6 py-4 ">
                <p className="text-dark">
                    <Link to="/">WWW.HOTBYM.COM</Link> “our website” is operated by Hotbym Limited, incorporated and registered in Nigeria with company number 1553933, whose registered address is at 8 Mustapha Street, Off Olayiwola Street, Lagos State, Nigeria, trading as Hotbym “we”, “us”, “our”.
                    If you would like to contact us about our Terms and Conditions, please call us on +234 909 999 0972 or email us at info@hotbym.com.
                </p>

                <h4 className="text-dark fw-bold py-3"> BY USING OUR WEBSITE, YOU AGREE TO THESE TERMS AND CONDITIONS </h4>

                <p className="text-dark">
                    <b>THESE TERMS OF USE</b><br />
                    By using our website, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree, you must not use our website.
                    We recommend that you print a copy of these Terms and Conditions for future reference.
                </p>

                <p className="text-dark">
                    <b>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</b> <br />
                    Our terms of use refer to the following additional terms, which also apply to your use of <Link to="/">WWW.HOTBYM.COM</Link>:
                    Our Privacy Policy: <Link to="/privacy">Privacy Policy</Link>; and
                    Our Cookies Policy: <Link to="/cookies">Cookie Policy</Link>.
                </p>

                <p className="text-dark">
                    <b>WE MAY MAKE CHANGES TO THESE TERMS</b><br />
                    These Terms and Conditions were last updated on 10th August 2020.
                    We keep our terms of use under regular review and may amend these Terms and Conditions from time to time. Every time you wish to use our website, please check to ensure you understand the terms of use that apply at that time. 
                </p>

                
                <p className="text-dark">
                    <b>WE MAY MAKE CHANGES TO OUR WEBSITE</b><br />
                    We may update and change our website from time to time to reflect changes to our services, products, our users’ needs, and our business priorities.
                    We will try to give you reasonable notice of any major changes.
                </p>

                <p className="text-dark">
                    <b>WE MAY SUSPEND OR WITHDRAW OUR SITE</b><br />
                    <Link to="/">WWW.HOTBYM.COM</Link> is made available free of charge. We do not guarantee that our website, or any content on it, will always be available or be uninterrupted. We may suspend, withdraw, or restrict the availability of all, or any part of our website for business and operational reasons. 
                    We will try to give you reasonable notice of any suspension or withdrawal.
                    You are also responsible for ensuring that anyone who accesses our website through your internet connection are aware of these Terms and Conditions, and other applicable terms of use of our website, and that they comply with them.
                </p>

                <p className="text-dark">
                    <b>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</b><br />
                    If you choose, or are provided with, a user identification code, password, or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
                    We have the right to disable any user identification code or password (whether chosen by you or allocated by us), at any time if, in our reasonable opinion, you have failed to comply with any of the provisions of these Terms and Conditions.
                    If you know or suspect that anyone other than you, knows your user identification code or password, you must promptly notify us.
                </p>

                <p className="text-dark">
                    <b>HOW YOU MAY USE MATERIAL ON OUR WEBSITE</b><br />
                    We are the owner/licensee of all intellectual property rights in <Link to="/">WWW.HOTBYM.COM</Link> and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                    You may draw the attention of others within your organisation to content posted on our website.
                    You may print one copy, and may download extracts, of any page(s) from our website for your personal use. If you print, copy, or download any part of our website in breach of these Terms and Conditions, your right to use our website will cease immediately, and you must, at our option, return or destroy any copies of the materials you have made.
                    You must not modify, in any way, the paper or digital copies of any materials you have printed off or downloaded.
                    You must not use any illustrations, photographs, graphics, or video or audio sequences separately from any accompanying text. Our status (and that of any identified contributors) as the authors of content on our website must always be acknowledged.
                    You must not use any part of the content on our website for commercial purposes without obtaining a licence to do so from us or our licensors.
                </p>

                <p className="text-dark">
                    <b>DO NOT RELY ON INFORMATION ON OUR SITE</b><br />
                    The content on <Link to="/">WWW.HOTBYM.COM</Link> is provided for general information only; it is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking or refraining from any action on the basis of the content on our website.
                    Although we make reasonable efforts to update the information on our website, we make no representations, warranties, or guarantees (whether express or implied) that the content on our website is accurate, complete, or up to date.
                </p>

                <p className="text-dark">
                    <b>WE ARE NOT RESPONSIBLE FOR SITES WE LINK TO</b><br />
                    Where our website contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources and such links should not be interpreted as approval by us of those linked sites or the information you may obtain from them.
                </p>

                <p className="text-dark">
                    <b>USER-GENERATED CONTENT IS NOT APPROVED BY US</b><br />
                    <Link to="/">WWW.HOTBYM.COM</Link> may include information and materials uploaded by other users (for example, to bulletin boards and chat rooms). This information, and these materials, have not been verified or approved by us. 
                    The views expressed by other users on our website do not represent our views or values.
                    Please contact us if you wish to complain about information and materials uploaded by other users.
                </p>

                <p className="text-dark">
                    <b>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</b><br />
                    Whether you are a consumer or a business user:
                    We do not exclude or limit, in any way, our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence, or the negligence of our employees, agents, or subcontractors, and for fraud or fraudulent misrepresentation.
                    Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any product or service to you.
                    If you are a business user:
                    We exclude all implied conditions, warranties, representations, or other terms that may apply to <Link to="/">WWW.HOTBYM.COM</Link> or any content on it.
                    We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under, or in connection with, the use of, or inability to use, our website, or the use of, or reliance on, any content displayed on our website.
                    In particular, we will not be liable for loss of profits, sales, business, or revenue; business interruption; loss of anticipated savings, business opportunity, goodwill, or reputation; or any indirect or consequential loss or damage.
                    If you are a consumer user:
                    Please note, we only provide our website for domestic and private use. You agree not to use our website for any commercial or business purposes, and that we have no liability to you for any business interruption, loss of profit, loss of business, or loss of business opportunity.
                    If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.
                </p>

                <p className="text-dark">
                    <b>HOW WE MAY USE YOUR PERSONAL INFORMATION</b><br />
                    We will only use your personal information as set out in our Privacy Policy: <Link to="/privacy">Privacy Policy</Link>.
                </p>

                <p className="text-dark">
                    <b>UPLOADING CONTENT TO OUR WEBSITE</b><br />
                    Whenever you make use of a feature that allows you to upload content to our website, or to make contact with other users of our website, you must comply with the content standards set out in these Terms and Conditions and all other policies on our website.
                    You warrant that any such contribution does comply with those standards, that you will be liable to us, and indemnify us, for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
                    Any content you upload to <Link to="/">WWW.HOTBYM.COM</Link> will be considered non-confidential and non-proprietary. 
                    You retain all ownership rights in the content you upload to our website, but you grant us a limited licence to use, store, and copy that content, and to distribute and make it available to third parties.
                    You must not post any prohibited content to our website, including (but not limited to), any content that is indecent, illegal, obscene, or pornographic. If we find your post to be in violation of this clause 13.5, your right to use our website will cease immediately.
                    We have the right to remove any posting you make on our website, if in our opinion, your post does not comply with the content standards set out in our Terms and Conditions and all other policies on our website.
                    We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our website, constitutes a violation of their intellectual property rights, or of their right to privacy.
                    You are solely responsible for securing and backing up your content.
                </p>

                <p className="text-dark">
                    <b>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</b><br />
                    We do not guarantee that our website will be secure or free from bugs or viruses.
                    You are responsible for configuring your information technology, computer programmes, and platform to access our website. You should use your own virus protection software.
                    You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful. 
                    You must not attempt to gain unauthorised access to our website; the server on which our website is stored; or any server, computer, or database connected to our website.
                    You must not attack our website via a denial-of-service attack or a distributed denial-of service attack.
                    We will report any such breach to the relevant law enforcement authorities and will co-operate by disclosing your identity to them. 
                    In the event of such a breach, your right to use our website will cease immediately.
                </p>

                <p className="text-dark">
                    <b>RULES ABOUT LINKING TO OUR WEBSITE</b><br />
                    You may link to the home page of our website, provided you do so in a way that is fair and legal, and does not damage our reputation or take advantage of it.
                    You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists.
                    You must not establish a link to our website in any site that is not owned by you.
                    Our website must not be framed on any other site, nor may you create a link to any part of our website, other than the home page.
                    We reserve the right to withdraw linking permission without notice.
                    If you wish to link to or make any use of any content on <Link to="/">WWW.HOTBYM.COM</Link> (other than that set out above), please contact us.
                </p>

                <p className="text-dark">
                    <b>GOVERNING LAW AND JURISDICTION</b><br />
                    If you are a consumer, please note that these Terms and Conditions, their subject matter, and formation are governed by the law of Nigeria. We both agree that the courts of Nigeria will have exclusive jurisdiction.
                    If you are a business, these Terms and Conditions, their subject matter, and formation (and any non-contractual disputes or claims) are governed by the law of Nigeria. We both agree to the exclusive jurisdiction of the courts of Nigeria.
                    BY USING OUR WEBSITE, YOU AGREE TO THESE TERMS AND CONDITIONS
                </p>
            </p>
        </div>

        <Footer />
      </>
    )
  }
  
  export default TermsAndConditions;