import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

function PrivacyPolicy() {
    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>

            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">Privacy Policy</h2>
            </div>

        </div>

        <div className="container p-5">
            <p className="fs-6 py-4 ">
                <p className="text-dark">
                    <Link to={"/"}>WWW.HOTBYM.COM</Link> (“our website”) is operated by. We are a private limited company, incorporated and registered in Nigeria with company number 1553933, whose registered address is at 8 Mustapha Street, Off Olayiwola Street, Lagos State, Nigeria, trading as Hotbym (“we”, “us”, “our”).
                    Hotbym respects your privacy and is committed to protecting your Personal Data. This Privacy Policy will inform you as to how we look after your Personal Data when you visit our website, about your privacy rights, and how the law protects you.
                </p>

                <p className="text-dark">
                    <b>INTRODUCTION</b><br />
                    It is important that you read this Privacy Policy together with any other privacy policy or any fair processing policy that Hotbym may provide on specific occasions when collecting or processing Personal Data about you, so that you are fully aware of how, and why, we are using your data. This Privacy Policy supplements other notices and policies and is not intended to override them.
                    We keep our Privacy Policy under regular review. This version was last updated on 7th August 2020.
                </p>

                <p className="text-dark">
                    <b>CONTROLLER</b> <br />
                        Hotbym is the controller and is responsible for your Personal Data.
                        We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy of our privacy practices, including any requests to exercise your legal rights, please contact the DPO using the details set out below:
                        <br />
                        The DPO: As delegated <br />
                        Email address: info@hotbym.com <br />
                        Postal address: 8 Mustapha Street, Off Olayiwola Street, Lagos State, Nigeria.
                        <br />
                        It is important that the Personal Data we hold about you is accurate and current. Please keep Hotbym informed if your Personal Data changes during your relationship with us.
                </p>

                <p className="text-dark">
                    <b>THE DATA WE COLLECT ABOUT YOU</b><br />
                    Hotbym may process your Personal Data without your knowledge or consent, where this is required or permitted by law.
                    We may collect, use, store, and transfer different kinds of Personal Data about you which we have grouped together as follows:
                    Identity Data includes first name, maiden name, last name, username, or similar identifier; marital status, title, date of birth, and gender.
                    Contact Data includes billing address, delivery address, email address, and telephone numbers.
                    Financial Data includes bank account and payment card details.
                    Transaction Data includes details about payments to, and from, you; and other details of products and services you have purchased from us.
                    Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.
                    Profile Data includes your username, password, purchases, and orders made by you, and your interests, preferences, feedback, and survey responses.
                    Usage Data includes information about how you use our website, products, and services.
                    Marketing and Communications Data includes your preferences in receiving marketing from Hotbym and our third parties, and your communication preferences.
                    We also collect, use, and share Aggregated Data, such as statistical or Demographic Data for any purpose. Aggregated Data could be derived from your Personal Data but is not considered Personal Data in law, as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data, which will be used in accordance with this Privacy Policy.
                    We do not collect any Special Categories of Personal Data about you. This includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, health, and genetic and biometric data. Nor do we collect any information about criminal convictions and offences.
                    <Link to="/">WWW.HOTBYM.COM</Link> is not intended for children and we do not knowingly collect data relating to children.
                 </p>

                
                <p className="text-dark">
                    <b>HOW YOUR PERSONAL DATA IS COLLECTED</b><br />

                    Hotbym use different methods to collect data from, and about, you: <br />
                    Direct interactions. You may give us your Identity, Contact, and Financial Data by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes Personal Data you provide when you: <br />
                    Apply for our services. <br />
                    Create an account on our website. <br />
                    Subscribe to our service or publications. <br />
                    Request marketing to be sent to you. <br />
                    Enter a competition, promotion, or survey. <br />
                    Give us feedback or contact us. <br />
                    Automated technologies or interactions. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this Personal Data by using cookies, server logs, and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. <br />
                    Third parties or publicly available sources. We will receive Personal Data about you from various third parties and public sources, as set out below: <br />
                    Analytics providers (such as Google) based outside Nigeria. <br />
                    Advertising networks based inside Nigeria. <br />
                    Search information providers based inside Nigeria. <br />
                    Contact Data, Financial Data, and Transaction Data from providers of   technical, payment, and delivery services. <br />
                    Identity Data and Contact Data from data brokers or aggregators based inside Nigeria. <br />
                    Identity Data and Contact Data from publicly available sources (for example, the Nigerian Electoral System) based inside Nigeria. <br />
                    Advertising networks based outside Nigeria. <br />
                    Search information providers based outside Nigeria. <br />
                   
                </p>

                <p className="text-dark">
                    <b>THIRD-PARTY LINKS</b><br />
                    <Link to="/">WWW.HOTBYM.COM</Link> may include links to third-party websites, plug-ins, and applications. Clicking on those links, or enabling those connections, may allow third parties to collect or share data about you. 
                    We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
                </p>

                <p className="text-dark">
                    <b>HOW WE USE YOUR PERSONAL DATA</b><br />
                
                    Hotbym will only use your Personal Data when the law allows us to. Most commonly, we will use your Personal Data in the following circumstances:
                    Where we need to perform the contract, we are about to enter into, or have entered into with you.
                    Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
                    Where we need to comply with a legal obligation.
                    Where we need to collect Personal Data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you, and as a result, Hotbym may be unable to provide you with a product or service. In this case, we may have to cancel any product or service you have with us, but we will notify you if this is the case at the time.
                    Generally, we do not rely on consent as a legal basis for processing your Personal Data, although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing, at any time, by contacting us.

                </p>

                <p className="text-dark">
                    <b>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</b><br />

                    Hotbym has set out below, in a table format, a description of all the ways we plan to use your Personal Data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
                    Please note that we may process your Personal Data for more than 1 lawful ground, depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground, we are relying on to process your Personal Data, where more than 1 ground has been set out in the table below.

                </p>

                <p className="text-dark">
                    <b>CHANGE OF PURPOSE</b><br />

                    Hotbym will only use your Personal Data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason, and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
                    If we need to use your Personal Data for an unrelated purpose, we will notify you and explain the legal basis which allows us to do so.
                </p>

                <p className="text-dark">
                    <b>DISCLOSURES OF YOUR PERSONAL DATA</b><br />

                    For the purposes set out in the table shown in clause 7, Hotbym may share your Personal Data with any third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your Personal Data in the same way as set out in this Privacy Policy.
                    Hotbym require all third parties to respect the security of your Personal Data and to treat it in accordance with the law. We do not allow our third-party service providers to use your Personal Data for their own purposes, and only permit them to process your Personal Data for specified purposes and in accordance with our instructions.
                    We do not transfer your Personal Data outside Nigeria.
                    
                </p>

                <p className="text-dark">
                    <b>MARKETING AND PROMOTIONAL OFFERS </b><br />
                    
                    Hotbym strive to provide you with choices regarding certain Personal Data uses, particularly around marketing and advertising. 
                    We will get your express opt-in consent before we share your Personal Data with any third party for marketing purposes.
                    We may use your Identity, Contact, Technical, Usage, and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services, and offers may be relevant for you (we call this marketing).
                    You will receive marketing communications from Hotbym if you have requested information from us or purchased services from us, and you have not opted out of receiving that marketing.
                    You can ask us or third parties to stop sending you marketing messages at any time, by logging into our website and checking or unchecking relevant boxes to adjust your marketing preferences, or by contacting us at any time.
                    Where you opt-out of receiving these marketing messages, this will not apply to Personal Data provided to us as a result of a product/service purchase, warranty registration, product/service experience, or other transactions.

                </p>

                <p className="text-dark">
                    <b>OUR USE OF COOKIES</b><br />
                    
                    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of <Link to="/">WWW.HOTBYM.COM</Link> may become inaccessible or not function properly. For more information about the cookies Hotbym use, please see our <Link to={"/cookies"}>Cookies Policy</Link>.
                </p>

                <p className="text-dark">
                    <b>DATA SECURITY</b><br />
                    
                    Hotbym have put in place appropriate security measures to prevent your Personal Data from being accidentally lost, altered, disclosed, or used or accessed in an unauthorized way. 
                    In addition, we limit access to your Personal Data to those employees, agents, contractors, and other third parties who have a business need-to-know. They will only process your Personal Data on our instructions and are subject to a duty of confidentiality.
                    We have put in place procedures to deal with any suspected Personal Data breach and will notify you, and any applicable regulator, of any breach where we are legally required to do so.
                </p>

                <p className="text-dark">
                    <b>DATA RETENTION</b><br />
                    
                    Hotbym will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements. 
                    We may retain your Personal Data for a longer period in the event of a complaint, or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
                    To determine the appropriate retention period for Personal Data, we consider the amount, nature, and sensitivity of the Personal Data; the potential risk of harm from unauthorised use or disclosure of your Personal Data; the purposes for which we process your Personal Data, and whether we can achieve those purposes through other means; and the applicable legal, regulatory, tax, accounting, or other requirements.
                    By law, we must keep basic information about our customers (including Contact, Identity, Financial, and Transaction Data) for tax purposes, for 6 years after they cease being customers.
                    In some circumstances, you can ask us to delete your data: see clause 14 for further information in relation to your legal rights.
                    In some circumstances, we will anonymise your Personal Data (so that it can no longer be associated with you) for research or statistical purposes. In which case, we may use this information indefinitely without further notice to you.

                </p>

                <p className="text-dark">
                    <b>YOUR LEGAL RIGHTS</b><br />
                    
                    Under certain circumstances, you have rights under data protection laws in relation to your Personal Data. Specifically, you have the right to:
                    Request access to your Personal Data (commonly known as a “data subject access request”). This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it.
                    Request correction of your Personal Data. This enables you to have corrected, any incomplete or inaccurate data we hold about you, though we may need to verify the accuracy of the new data you provide to us.
                    Request erasure of your Personal Data. This enables you to ask us to delete or remove any Personal Data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully, or where we are required to erase your Personal Data to comply with local law. Please note, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                    Object to processing of your Personal Data where we are relying on a legitimate interest (or those of a third party), and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                    Request restriction of processing your Personal Data. This enables you to ask us to suspend the processing of your Personal Data in the following scenarios:
                    If you want us to establish the data’s accuracy.
                    Where our use of the data is unlawful, but you do not want us to erase it.
                    Where you need us to hold the data, even if we no longer require it, as you need it to establish, exercise, or defend legal claims.
                    You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                    Request transfer of your Personal Data to you, or to a third party. We will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note, that this right only applies to automated information which you initially provided consent for us to use, or where we used the information to perform a contract with you.
                    Right to withdraw consent where we are relying on consent to process your Personal Data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case, at the time you withdraw your consent
                    If you wish to exercise any of the rights set out above, please contact us.
                </p>

                <p className="text-dark">
                    <b>REQUESTING ACCESS TO YOUR PERSONAL DATA</b><br />
                    You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, Hotbym may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
                    Hotbym may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                    We try to respond to all legitimate requests within 1 month. Occasionally, it could take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you and keep you updated.
                </p>

                <p className="text-dark">
                    <b>GLOSSARY</b><br />
                    “Comply with a legal obligation” means processing your Personal Data where it is necessary for compliance with a legal obligation that we are subject to.
                    “Legitimate Interest” means the interest of our business in conducting and managing our business, to enable us to give you the best service/product, and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative), and your rights, before we process your Personal Data for our legitimate interests. We do not use your Personal Data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can contact us to obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities.
                    “Performance of Contract” means processing your data where it is necessary for the performance of a contract to which you are a party, or to take steps at your request before entering such a contract.
                    “Personal Data” or “personal information” means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
                    “External Third Parties”
                    Service providers acting as processors based inside Nigeria, who provide IT and system administration services.
                    Professional advisers acting as processors or joint controllers, including lawyers, bankers, auditors, and insurers based in Nigeria, who provide consultancy, banking, legal, insurance, and accounting services.
                    Regulators and other authorities acting as processors or joint controllers based in Nigeria, who require reporting of processing activities in certain circumstances.
                </p>

            </p>
        </div>
        <Footer />
      </>
    )
  }
  
  export default PrivacyPolicy;