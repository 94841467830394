/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from '../apis/axios';
import useAuth from '../hooks/useAuth';

const url = "/auth/sign-out";
function Header() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const start = new Date();
    const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
    const end = new Date();
    const endD = new Date(end.setDate(end.getDate()+1)).toISOString().split("T")[0]; 


    const criteria = {
        categoryId : 1,
        stateId: 2,
        start: startD,
        end: endD
    };

    async function logout(){
       
        try{
            // Axios API.
            const response = await axios.post(url, 
                JSON.stringify({}), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });

                console.log(response.data);
                
                setAuth({});
                window.location.reload(true);
                
        }catch(e){
            console.error(e);
        }
        
    }


  return (
    <header className="header">
        <div className="bg-dark nav-bar">
            <div className="container">
                <div className="d-flex justify-content-between">
                        
                    <div className="logo mt-2">
                        <Link  to={"/"}><img src={`${window.location.origin}/images/hotbym-logo.png`} alt="HOTBYM" /></Link>
                    </div>
                        
                    <div className="d-flex mt-3">
                        <Link className="text-white fs-6 fw-light px-3" to={"/search"} state={criteria}>Explore</Link>
                    {
                        auth?.userId
                            ? (<Link className="text-white fs-6 fw-light px-3" onClick={logout}>Logout</Link>)
                            : (<a data-bs-toggle="modal" onMouseOver={(e)=>{e.target.style.cursor='pointer'}} data-bs-target="#login" className="text-white fs-6 fw-light px-3">Login</a>)
                    }
                    </div>
                </div>
            </div>		
        </div>
    </header>
  )
}

export default Header