import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

function About() {
    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>

            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">About</h2>
            </div>

        </div>

        <div>
          <div className="container p-5">
            <div className="row">
              <div className="col-md-8 py-4">
                <p className="fs-6">
                  Founded in 2019 in Lagos, Nigeria, Hotbym.com is a player in the online booking and hospitality industry, from a small Lagos start-up and intends to be one of the continent’s leading digital travels booking platform. One of Hotbym.com’s mission is to make it easier for everyone to experience the seamless online booking process.
                </p>
                <p className="fs-6">
                  By investing in the technology that helps take the friction out of travel, Hotbym.com seamlessly connects thousands of travellers with memorable experiences, with a range of options and incredible places to stay - from hotels to apartments and much more. As the best travel and booking marketplaces for both individuals and corporate brands of all sizes, Hotbym.com enables properties all over the nation Nigeria and Africa to reach a global audience and grow their businesses.
                </p>
              </div>
              <div className="col-md-4 p-5">
                <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>
          

    <div className="top-destination bg-shade">

      <div className="container py-5 px-5">
      <h3 className="text-dark">What Hotbym Offers</h3>

          <div className="row py-5">

            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">Incredible Choice</h5>
              <p>Whether you want to stay in a chic city hotel, cosy apartment, luxury beach resort, Hotbym.com provides you with amazing diversity and breadth of choice - all in one place.</p>
            </div>
            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">Low rates</h5>
              <p>Hotbym.com guarantees to offer you the best available rates. And with our promise to price match, you can be rest assured that you’re always getting a great deal.</p>
            </div>
            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">Instant confirmation</h5>
              <p>At Hotbym.com, every reservation is instantly confirmed. Once you’ve found your perfect stay, a few clicks are all it takes.</p>
            </div>
            
          </div>

          <div className="row py-5">
            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">No reservation fees</h5>
              <p>We don’t charge you any booking fees or add any administrative charges. And in many cases, your booking can be cancelled free of charge.</p>
            </div>

            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">Secure booking</h5>
              <p>We facilitate hundreds of thousands of transactions every day through our secure platform, and work to the highest standards to guarantee your privacy. For further details, please check our <Link to="/privacy">Privacy Statement.</Link></p>
            </div>
            <div className="col-md-4 p-4">
              {/* <img src={`${window.location.origin}/images/hotbym-logo.png`} className="img-responsive" width="100%" alt="" /> */}
              <h5 className="text-dark fw-bold">24/7 support</h5>
              <p>Whether you’ve just booked or are already enjoying your trip, our customer experience team are on hand around the clock to answer your questions and advocate on your behalf.</p>
            </div>

            <div className="col-md-3 p-4"></div>

            <div className="col-md-3 p-4"></div>
            
          </div>

      </div>
    </div>


        <Footer />
      </>
    )
  }
  
  export default About;