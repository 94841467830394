import { Route, Routes } from 'react-router-dom';
import './App.css';
import Container from './components/Container';
import Authenticate from './components/Authenticate';
import Home from './pages/Home';
import Single from './pages/Single';
import Page404 from './pages/404';
import Search from './pages/Search';
import About from './pages/About';
import Contact from './pages/Contact';
import ChangePassword from './components/authentication/ChangePassword';
import Payment from './pages/Payment';
import ViewBooking from './pages/ViewBooking';
import Profile from './pages/Profile';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Request from './pages/Request';
import CookiePolicy from './pages/CookiePolicy';
import Faqs from './pages/Faqs';
import Blog from './pages/Blog';
import HotelListing from './pages/HotelListing';


function App() {
  return (
    <>
      <Routes>
      <Route element={<Authenticate/>}>
          <Route path='/' element={<Container />}>
            <Route index element={<Home/>} />
            <Route path='/property/:propertyId' element={<Single />} />
            <Route path='/property/:propertyId/room/:roomId' element={<Single />} />
            <Route path='/search' element={<Search/>} />
            <Route path='/track/bookings' element={<ViewBooking/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/change-password/:token' element={<ChangePassword />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/cookies' element={<CookiePolicy />} />
            <Route path='/requests' element={<Request/>} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/listings' element={<HotelListing/>} />
          </Route>

        
          {/* <Route path='/' element={<Core/>}> */}
            {/* <Route path='/dashboard' element={<Dashboard/>} />
            <Route path='/users' element={<Users/>} />
            <Route path='/roles' element={<Roles/>} />
            <Route path='/amenities' element={<Amenities/>} />
            <Route path='/bookings' element={<Bookings/>} />
            <Route path='/organisations' element={<Organisations/>} />
            <Route path='/categories' element={<Categories/>} />
            <Route path='/locations' element={<Locations/>} />
            <Route path='/rooms' element={<Rooms/>} />
            <Route path='/properties' element={<Properties/>} />
            <Route path='/payments' element={<Payments/>} />
            <Route path='/transactions' element={<Transactions/>} />
            <Route path='/uploads' element={<Uploads/>} />
            <Route path='/notifications' element={<Notifications/>} /> */}
          {/* </Route> */}
        </Route>

        <Route path='*' element={<Page404/>} />

      </Routes>
      




     
		



		











		

    </>
  );
}

export default App;
