import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Blog() {
  return (
    <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>

            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">Blog</h2>
            </div>

        </div>

        <div className="container p-5">
            <div className="row">
                <div className="col-md-8 p-3">
                <article className="blog-post text-dark">
                    <h3 className="blog-post-title text-dark">New feature</h3>
                    <p className="blog-post-meta text-dark ">December 14, 2022 by <a href="#">Admin</a></p>

                    <p className='text-dark'>This is some additional paragraph placeholder content. It has been written to fill the available space and show how a longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
                    <ul>
                    <li>First list item</li>
                    <li>Second list item with a longer description</li>
                    <li>Third list item to close it out</li>
                    </ul>
                    <p className='text-dark'>This is some additional paragraph placeholder content. It's a slightly shorter version of the other highly repetitive body text used throughout.</p>
                
                </article>
                <hr />
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
        <Footer />
    </>)
}

export default Blog