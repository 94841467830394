import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from '../apis/axios';
import Footer from '../components/Footer'
import Header from '../components/Header'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Gallery } from 'react-grid-gallery';
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
import useAuth from '../hooks/useAuth';
import { difference, indexOf } from 'lodash';

function Single(props) {
	
	const location = useLocation();
	const params = useParams();
	const propertyId = params?.propertyId;
	const roomId = params?.roomId;
	const navigate = useNavigate();

	const [formDisplay, setFormDisplay] = useState("");
	const [singleDisplay, setSingleDisplay] = useState("d-none");

	const { auth } = useAuth();
	const [totalAmount, setTotalAmount] = useState(0);
	const [arrival, setArrival] = useState('0000-00-00 00:00');
	const [departure, setDeparture] = useState('0000-00-00 00:00');
	const [hourlyCheck, setHourlyCheck] = useState("d-none");
	const [singleDate, setSingleDate] = useState(null);
	const [inputList, setInputList] = useState([]);
	const [propertyName, setPropertyName] = useState("...");
	const [propertyAddress, setPropertyAddress] = useState("...");
	const [propertyDescription, setPropertyDescription] = useState("...");
	const [rooms, setRooms] = useState([]);
	const [roomIds, setRoomIds] = useState([]);
	const [dateRange, setDateRange] = useState([null, null]);
	const [gallery, setGallery] = useState([]); 
	const [index, setIndex] = useState(-1);
	const galleryImages = [];
    let [startDate, endDate] = dateRange;
	const [hoursHr, setHoursHr] = useState("3");

	const currentImage = gallery[index];
	const nextIndex = (index + 1) %gallery.length;
	const nextImage = gallery[nextIndex] || currentImage;
	const prevIndex = (index +gallery.length - 1) %gallery.length;
	const prevImage = gallery[prevIndex] || currentImage;

	const handleClick = (index, item) => {console.log(index); setIndex(index); };
	const handleClose = () => setIndex(-1);
	const handleMovePrev = () => setIndex(prevIndex);
	const handleMoveNext = () => setIndex(nextIndex);

	async function getProperty(){

		if(roomId){
			const ids = location.state.rooms.map(item => item.id);
			setRoomIds(ids);
		}
		
        try{
            const property = await axios.get("/properties/"+propertyId);
			const propertyResult = property.data;
			setPropertyName(propertyResult.name);
			setPropertyAddress(propertyResult.address);
			setPropertyDescription(propertyResult.description);
			
			if(roomId){
				const newRooms = propertyResult.Rooms.map((item) => {
					const ids = location.state.rooms.map(item => item.id);
					if (ids.indexOf(item.id) !== -1){
						return item;
					}
				});
				setRooms(newRooms);
				console.log("Here1 ", roomIds);
				console.log("Here2 ",newRooms);
			}else {
				setRooms(propertyResult.Rooms);
			}


			if (property.status === 200) {
				const images = await axios.post("/properties/images",
				JSON.stringify({ propertyId: parseInt(propertyId)}), 
				{
					headers: { 'Content-Type': 'application/json' },
					withCredentials: true
				});

				let imageResult = null;
				if (images.data.length > 0){
					imageResult = images.data[0].url;
					let countImgs = parseInt(imageResult.split("~")[1]);
					for(let i = 0; i < countImgs; i++){
						galleryImages.push({ src: `${imageResult}nth/${i}/`, width: 365, height: 180 });
					}
				}

				const roomWithImages = property.data.Rooms.map(async(item) => {
					const roomImages = await axios.post("/rooms/images",
					JSON.stringify({ roomId: parseInt(item.id)}), 
					{
						headers: { 'Content-Type': 'application/json' },
						withCredentials: true
					});

					if(roomImages.data.length > 0){
						const imageUrls = roomImages.data.map((innerItem) => {
							if(innerItem?.url){
								return innerItem.url;
							}
						});
						return (imageUrls[0]);
					}
				});

				const roomImageUrl = await Promise.all(roomWithImages);
				roomImageUrl.map((item, index) => {
					const roomImg = document.getElementById('roomImg'+index);
					if(item){
						roomImg.style.backgroundImage = `url(${item}nth/0/)`;
						let countImgs = parseInt(item.split("~")[1]);
						for(let i = 0; i < countImgs; i++){
							galleryImages.push({ src: `${item}nth/${i}/`, width: 244, height: 180 });
						}
					}
				});
            }
			setGallery(galleryImages);

		}catch(err){
			console.log(err);
			window.history.back();
		}
	}

	useEffect(() => { 
		getProperty();
		if (roomId){
			getLimit();
			setFormDisplay("d-none");
			setSingleDisplay("");
		}
	}, []);

	function handleSearch(e, room, index){

		if ((location.state  != null) && (location.state?.time))
		{
			const time =  location.state.time;
			const hours =  location.state.hours;
			let endTime = " "+(parseInt(hours) + parseInt(time))+":00";

			if(parseInt(time) > 9){
				endTime = " "+(parseInt(hours) + parseInt(time))+":00";
			}
			setArrival(location.state.start+" "+time);
			setDeparture(location.state.start+""+endTime);
			const priceHr = (parseInt(location.state.priceHr) * parseInt(hours));
			handleAddSelected(e, room, index, priceHr);
		}

		if ((location.state  != null) && (!location.state?.time))
		{
			setArrival(location.state.start+" 00:00");
			setDeparture(location.state.end+" 00:00");
			handleAddSelected(e, room, index);
		}

	}

	// handle click event of the Add button
	function handleAddSelected(e, room, index, price=0) {
		//e.preventDefault();
		const qty = document.getElementById('limit'+index).value;
		if(roomId){
			if(price == 0){
				let Difference_In_Days = 1
				Difference_In_Days += parseInt(location.state.end.split("-")[2]) -  parseInt(location.state.start.split("-")[2]);;
				price = room.price * Difference_In_Days;
			}

			if(location.state?.time){
				price = (parseInt(room.pricePerHour) * parseInt(location.state.hours));
			}



		}else{
			if(price == 0){
				let Difference_In_Days = 1
				const Difference_In_Time = endDate.getTime() - startDate.getTime();
				Difference_In_Days += Difference_In_Time / (1000 * 3600 * 24);
				price = room.price * Difference_In_Days;
				//console.log(startDate, " - ", endDate, " Difference: ", Difference_In_Days, );
			}
			
			if (hourlyCheck != "d-none"){
				const hoursInput = document.getElementById('hoursInput').value;
				price = (parseInt(room.pricePerHour) * parseInt(hoursInput));
				// price  = (parseInt(room.pricePerHour) * parseInt(hoursInput));
			}
		}
			

		

		
		// console.log(room);
		const totalRoomPrice = (parseInt(price) * parseInt(qty));
		const fields = {
			roomId: room.id,
			roomType: room.roomType,
			quantity: qty,
			price: price,
			total: totalRoomPrice
		};
		if (e.target.checked){

			setTotalAmount(totalAmount+totalRoomPrice);
			document.getElementById('limit'+index).disabled = true;
			setInputList([...inputList, fields]);
		} else {
			setTotalAmount(totalAmount-totalRoomPrice);
			document.getElementById('limit'+index).disabled = false;
			const list = [...inputList];
			console.log("here for the remove second value: ", list, " spliced value ", room);
			const indexArr = list.map(function(e) { return e.roomType; }).indexOf(room.roomType);

			list.splice(indexArr, 1);
			setInputList(list);
			console.log(inputList);
		}
		// console.log(e);
	}

	async function getLimit(start, end, index){

			rooms.map(async (item) => {
			document.getElementById('limit'+index).disabled = false;
			console.log(document.getElementById('select'+index));
			document.getElementById('btn-check'+index).checked = false;
			document.getElementById('limit'+index).value = 1;
			const statusInfo = document.getElementById('status'+index);
			const selectBtn = document.getElementById('select'+index);
			const limitSelect = document.getElementById('limit'+index);

			//console.log(new Date(start).toISOString().split("T")[0], " - ", new Date(end).toISOString().split("T")[0]);

			
			try{
				const response = await axios.post("/bookings/unavailable/range", 
					JSON.stringify({ 
						start: start,
						end: end,
						roomId: item.id,
						limit: item.limit 
					}),
					{
						headers: { 'Content-Type': 'application/json' },
						withCredentials: true
					});
	
					if(response.status === 200 ){
						const exDates = response.data.map(item => new Date(item.date) );
						selectBtn.classList.remove('disabled');
						limitSelect.max = 0;
						if(exDates.length === 0 ) {
							try{
								const availableResponse = await axios.post("/bookings/available/", 
								JSON.stringify({ 
									start: start,
									end: end,
									roomId: item.id,
									limit: item.limit
								}),
								{
									headers: { 'Content-Type': 'application/json' },
									withCredentials: true
								});
								
								if (availableResponse.status === 200 ){
									if(availableResponse.data[0]?.quantity){
										limitSelect.max = (item.limit - availableResponse.data[0].quantity);
									}else{
										limitSelect.max = item.limit;
									}
								}
				
							}catch(err){
								console.log(err);
							}
							selectBtn.classList.remove('disabled');
							statusInfo.classList.add('d-none');
						}else {
							selectBtn.classList.add('disabled');
							statusInfo.classList.remove('d-none');
							limitSelect.max = 0;
						}
					}
			}catch(e){
				console.log(e);
			}
		});	
	}
	
	async function getUnavailableDatesInRange(e, rooms){

		if(e[1] !== null){
			setTotalAmount(0);
			let end = new Date(e[1]);
			let start = new Date(e[0]);
			setArrival(new Date(start.setDate(start.getDate() + 1)).toISOString().split('T')[0]+" 00:00");
			setDeparture((new Date(end.setDate(end.getDate() + 1))).toISOString().split('T')[0]+" 00:00");
			
			if (e[0] === e[1]) { 
				const time =  document.getElementById('timeInput').value;
				const hours =  document.getElementById('hoursInput').value;

				let endTime = " "+(parseInt(hours) + parseInt(time))+":00";

				if(parseInt(time) > 9){
					endTime = " "+(parseInt(hours) + parseInt(time))+":00";
				}
				setArrival(new Date (start.setDate(start.getDate() )).toISOString().split('T')[0]+" "+time);
				setDeparture((new Date (end.setDate(end.getDate() ))).toISOString().split('T')[0]+endTime);
			}
			
			
			setInputList([]);
			rooms.map(async (item, index) => {
				document.getElementById('limit'+index).disabled = false;
				console.log(document.getElementById('select'+index));
				document.getElementById('btn-check'+index).checked = false;
				document.getElementById('limit'+index).value = 1;
				const statusInfo = document.getElementById('status'+index);
				const selectBtn = document.getElementById('select'+index);
				const limitSelect = document.getElementById('limit'+index);

				//console.log(new Date(start).toISOString().split("T")[0], " - ", new Date(end).toISOString().split("T")[0]);
				
				start = new Date(start).toISOString().split("T")[0];
				end = new Date(end).toISOString().split("T")[0];

				
				try{
					const response = await axios.post("/bookings/unavailable/range", 
						JSON.stringify({ 
							start: start,
							end: end,
							roomId: item.id,
							limit: item.limit 
						}),
						{
							headers: { 'Content-Type': 'application/json' },
							withCredentials: true
						});
		
						if(response.status === 200 ){
							console.log("Heree ",response.data)
							const exDates = response.data.map(item => new Date(item.date) );
							selectBtn.classList.remove('disabled');
							// statusInfo.classList.remove('d-none');
							limitSelect.max = 0;
							if(exDates.length === 0 ) {
								try{
									const availableResponse = await axios.post("/bookings/available/", 
									JSON.stringify({ 
										start: start,
										end: end,
										roomId: item.id,
										limit: item.limit
									}),
									{
										headers: { 'Content-Type': 'application/json' },
										withCredentials: true
									});
									
									if (availableResponse.status === 200 ){
										if(availableResponse.data[0]?.quantity){
											limitSelect.max = (item.limit - availableResponse.data[0].quantity);
										}else{
											limitSelect.max = item.limit;
										}
									}
					
								}catch(err){
									console.log(err);
								}
								selectBtn.classList.remove('disabled');
								statusInfo.classList.add('d-none');
							}else {
								selectBtn.classList.add('disabled');
								statusInfo.classList.remove('d-none');
								limitSelect.max = 0;
							}
						}
				}catch(e){
					console.log(e);
				}
			});	
		}
	}

	function handleHourlyCheck(event){
		setArrival('0000-00-00 00:00');
		setDeparture('0000-00-00 00:00');
		const inOutDate = document.getElementById("check-in-out");
		inOutDate.classList.remove('d-none')
		setHourlyCheck("d-none");
		if (event.target.checked === true) {
			setHourlyCheck("row mt-3 mx-3"); 
			inOutDate.classList.add('d-none');
		}
	}

	function handleSubmit(){
		if(inputList.length > 0){
			const results = {
				start: arrival,
				end: departure,
				propertyId: propertyId ,
				type: "Personal",
				status: "pending",
				rooms: inputList,
				total: totalAmount
			};
			
			navigate("/payment", { state: results });

			console.log(results);
		}
		
	}

  return (
    <>
        <Header />

        <div className="container pt-5 px-5 ">
			<div className="row gx-5 pt-5 px-5 ">
				
				<div className="col-md-8">

					<div className="image-grid">
						<Gallery
							images={gallery}
							onClick={handleClick}
							enableImageSelection={false}
						/>
						{/* {currentImage && (
							<Lightbox
							mainSrc={currentImage.src}
							nextSrc={nextImage.src}
							prevSrc={prevImage.src}
							onCloseRequest={handleClose}
							onMovePrevRequest={handleMovePrev}
							onMoveNextRequest={handleMoveNext}
							/>
						)} */}

					</div>
					

					<div className="title-section p-2">
						<div className="row">
							<div className="col py-1">
								<h3 className="text-dark pt-2 fw-bold">{propertyName}</h3>
							</div>
							<div className="col-md-8 text-dark my-auto" align="right"><i className="fa fa-map-marker fa-lg text-danger"></i>&nbsp;&nbsp; {propertyAddress}</div>
						</div>
					</div>

					<nav>
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
						  <button className="nav-link active" id="nav-rooms-tab" data-bs-toggle="tab" data-bs-target="#nav-room" type="button" role="tab" aria-controls="nav-room" aria-selected="true">Rooms</button>
						  <button className="nav-link" id="nav-overview-tab" data-bs-toggle="tab" data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview" aria-selected="false">Overview</button>
						</div>
						<hr className='my-0'/>
					</nav>

					<div className="tab-content" id="nav-tabContent">

						<div className="tab-pane fade show active px-3" id="nav-room" role="tabpanel" aria-labelledby="nav-rooms-tab">

						<form className={singleDisplay} >

							{
								rooms.map((room, index) => {

										if (roomIds.includes(room.id)){

											return (
												
													<div className="row my-4 border border-opacity-25 rounded" key={index}>
														<div className="col-md-4 img-content rounded-start" id={'roomImg'+index} style={{background: "url(/images/default.png)", backgroundSize: "cover"}} ></div>

														<div className="col-md-8 p-4 shadow-sm room-content">

															<div className="row">
																<div className="col-md-7 rounded-end">
																	<p className="h5 text-danger fw-bold">{ room.roomType }</p>
																</div>
																
																<div className="col-md-5">
																	<div className="d-flex">
																		<div className="col text-danger"><b>Daily: </b></div>
																		<div className="col text-danger"><b>Hourly: </b></div>
																	</div>
																	<div className="d-flex">
																		<div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.price}</div>
																		<div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div>
																	</div>
																	{/* <div className="text-danger fw-normal pt-1 h6" align="right"><span className="h6 text-secondary">₦</span>{room.price} - <span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div> */}
																</div>
															</div>

															<div className="text-secondary fw-normal pb-2"><b>Adults:</b> { room.adults } &nbsp; | &nbsp; <b>Children:</b> { room.children }  &nbsp; | &nbsp; <b>Beds:</b> { room.beds } </div>
															<div className="row pt-3">
																<div className="col-md-4 row">
																	<div className="col-md-3 mx-auto">
																		<label className='fw-bold text-secondary '>Qty: </label> 
																	</div>
																	<div className="col-md-8">
																		<input type="number" name="limit" min={1} defaultValue={1} className="form-control form-control-sm" id={'limit'+index} onFocus={(e) => { getLimit(location.state.start, location.state.end, index)}} onKeyDown={(e) => {e.preventDefault(); e.stopPropagation();}}  />
																	</div>
																</div>
																<div className="col" align='center'>
																	<span className='text-danger fs-6 d-none' id={'status'+index}>Not Available!</span>
																</div>
																<div className="col" align="right">
																	<input type="checkbox" className="btn-check" onChange={(e)=> {handleSearch(e, room, index);}} id={"btn-check"+index} autoComplete="off" />
																	<label className="btn btn-outline-danger btn-sm px-5 py-1 rounded text-uppercase "  id={'select'+index} htmlFor={"btn-check"+index}>select</label>
																</div>
															</div>
														</div>
													</div>
											)
										}
								})
							}

							</form>


							<form className={formDisplay} >

								<div className="row py-4">
									<div className="col-md-12" id="check-in-out">
                                        <label htmlFor="arrivalDepartureInput" className='text-dark fw-bold pb-2'>Check-in / Check-out:<span className='text-danger'>*</span></label>
										<div className="input-group">
											<span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar fa-lg text-danger"></i></span>
											<span className='w-75'>
												<DatePicker
													id='arrivalDepartureInput'
													//dateFormat="yyyy-MM-dd"
													className="form-control"
													selectsRange={true}
													startDate={startDate}
													endDate={endDate}
													onChange={(update) => { 
														// console.log(update);
														// updateMaxQuantity(update);
														getUnavailableDatesInRange(update, rooms);
														setDateRange(update);
													}}
													minDate={new Date()}
													monthsShown={2}	
													placeholderText={"yyyy-mm-dd - yyyy-mm-dd"}
												/>
											</span>	
										</div>
                                    </div>
								</div>

								<div className="row">
									<div className="col-md-2 px-0 my-auto">
										{/* <input className="form-check-input"  type="checkbox" value="" id="flexCheckDefault" /> */}
										<label className="form-check-label text-dark fw-bold px-2" htmlFor="flexCheckDefault">Hourly: </label>
										<div className="form-check form-switch mx-5">
											<input className="form-check-input" onChange={(e)=> { handleHourlyCheck(e) }} type="checkbox" role="switch" id="flexSwitchCheckChecked" />
										</div>
									</div>

								</div>

								<div className={hourlyCheck}>

								<div className="col-md-6">
                                        <label htmlFor="arrivalInput" className='text-dark fw-bold pb-2'>Check-in<span className='text-danger'>*</span></label>
										<div className="input-group">
											<span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar fa-lg text-danger"></i></span>
											<span className='w-75'>
												<DatePicker
													id='arrivalInput'
													className="form-control"
													onChange={(update) => { 
														getUnavailableDatesInRange([update, update], rooms);
														setDateRange([update, update]);
														setSingleDate(update);
													}}
													selected={singleDate}
													minDate={new Date()}
													placeholderText={"yyyy-mm-dd"}
												/>
											</span>	
										</div>
                                    </div>

									<div className="col-md-2 my-auto">
										<label className="form-check-label text-dark fw-bold px-1 pb-2">Hours: <span className='text-danger'>*</span></label>
										<select name="" id="hoursInput"  onChange={ (e) => { getUnavailableDatesInRange([singleDate, singleDate], rooms); setHoursHr(e.target.value)}} className="form-select">
											<option value="3">3</option>
											<option value="6">6</option>
											<option value="9">9</option>
											<option value="12">12</option>
										</select>
									</div>
									
									<div className="col-md-3 my-auto">
										<label className="form-check-label text-dark fw-bold px-1 pb-2">Check-in Time: <span className='text-danger'>*</span></label>
										<select name="" id="timeInput" onChange={ () => { getUnavailableDatesInRange([singleDate, singleDate], rooms)}} className="form-select">
										{
                                    (hoursHr == "12")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "9")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "6")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "3")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00 ">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                        <option value="19:00">19:00 pm</option>
                                        <option value="20:00">20:00 pm</option>
                                        <option value="21:00">21:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
										</select>
									</div>

									<div className="col"></div>
								</div>

								{
									rooms.map((room, index) => {
									
										return (
											
												<div className="row my-4 border border-opacity-25 rounded" key={index}>
													<div className="col-md-4 img-content rounded-start" id={'roomImg'+index} style={{background: "url(/images/default.png)", backgroundSize: "cover"}} ></div>

													<div className="col-md-8 p-4 shadow-sm room-content">
														<div className="row">
															<div className="col-md-7 rounded-end">
																<p className="h5 text-danger fw-bold">{ room.roomType }</p>
															</div>
															
															<div className="col-md-5">
															<div className="d-flex">
																<div className="col text-danger"><b>Daily: </b></div>
																<div className="col text-danger"><b>Hourly: </b></div>
															</div>
															<div className="d-flex">
																<div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.price}</div>
																<div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div>
															</div>
															{/* <div className="text-danger fw-normal pt-1 h6" align="right"><span className="h6 text-secondary">₦</span>{room.price} - <span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div> */}
															</div>
														</div>

														<div className="text-secondary fw-normal pb-2"><b>Adults:</b> { room.adults } &nbsp; | &nbsp; <b>Children:</b> { room.children }  &nbsp; | &nbsp; <b>Beds:</b> { room.beds } </div>
														<div className="row pt-3">
															<div className="col-md-4 row">
																<div className="col-md-3 mx-auto">
																	<label className='fw-bold text-secondary '>Qty: </label> 
																</div>
																<div className="col-md-8">
																	<input type="number" name="limit" min={1} defaultValue={1} className="form-control form-control-sm" id={'limit'+index} onKeyDown={(e) => {e.preventDefault(); e.stopPropagation();}} disabled={true} />
																</div>
															</div>
															<div className="col" align='center'>
															<span className='text-danger fs-6 d-none' id={'status'+index}>Not Available!</span>
															</div>

															<div className="col" align="right">
															<input type="checkbox" className="btn-check" onChange={(e)=> {handleAddSelected(e, room, index);}} id={"btn-check"+index} autoComplete="off" />
															<label className="btn btn-outline-danger btn-sm px-5 py-1 rounded text-uppercase disabled"  id={'select'+index} htmlFor={"btn-check"+index}>select</label>
																
															</div>
														</div>

													</div>
												</div>

											
										)
									})
								}

							</form>
							
					
						</div>
						
						<div className="tab-pane fade py-2 px-2" id="nav-overview" role="tabpanel" aria-labelledby="nav-overview-tab">
							
							<p className="text-dark">{propertyDescription}</p>
							
						</div>

					</div>


					<p className='text-danger fw-bold'>
							{/* {JSON.stringify(inputList)} */}
					</p>
				</div>



				<div className="col-md-4">

					<div className="side-floater rounded" >
						<div className="row">
							<div className="col">
								<div className="text-dark fw-bold fs-6"><i className="fa fa-calendar fa-lg text-danger">&nbsp;&nbsp;</i>Check-in</div>
								<p className="text-dark pt-1">{arrival}</p>
								<hr />
							</div>
							<div className="col">
								<div className="text-dark fw-bold fs-6"><i className="fa fa-calendar fa-lg text-danger">&nbsp;&nbsp;</i>Check-out</div>
								<p className="text-dark pt-1">{departure}</p>
								<hr />
							</div>
						</div>

						{
							inputList.map((item, index) => {
								return (
									<div className='row' key={index}>
										<h4 className="text-dark fw-bold ">{item.roomType}</h4>
										<div className="col-md-6 ">
											<p className="text-dark my-0">Quantity</p>
										</div>
										<div className="col-md-6">
											<p className="text-dark my-0" align="right">{item.quantity}</p>
										</div>
										<div className="col-md-6">
											<p className="text-dark my-0">Room Price</p>
										</div>
										<div className="col-md-6">
											<p className="text-dark my-0" align="right">₦{Math.floor(item.price)}.00</p>
										</div>
										<hr />
									</div>
								)
							})
						}
						
						<div className="row">
							<div className="col">
								<p className="text-dark fw-bold pt-1 h2">Total</p>
							</div>
							<div className="col">
								<div className="text-danger fw-bold pt-1 h1" align="right"><span className="h5 fw-bold text-dark">₦</span>{totalAmount}</div>
							</div>
							<p className="pt-1 text-dark fw-normal ">By completing this booking you agree to the <Link to="/terms">terms and condition and privacy policy</Link>.</p>
							{
								auth.userId
									?(<button onClick={() => { handleSubmit() }} className="btn btn-danger btn-lg px-5 py-2 mt-3 rounded text-uppercase"><span className="fw-bolder fs-6">complete booking</span></button>)
									// ?(<Link to="/payment" state={{ results: "results" }} className="btn btn-danger btn-lg px-5 py-2 mt-3 rounded text-uppercase"><span className="fw-bolder fs-6">complete booking</span></Link>)
									:(<button data-bs-toggle="modal" data-bs-target="#login" className="btn btn-danger btn-lg px-5 py-2 mt-3 rounded text-uppercase"><span className="fw-bolder fs-6">complete booking</span></button>)
							}
							
							{/* <button type="submit" className="btn btn-danger btn-lg px-5 py-2 mt-3 rounded text-uppercase"><span className="fw-bolder fs-6">complete booking</span></button> */}
						</div>
					</div>
				</div>
			</div>
		</div>


        <Footer />
    </>
    
  )
}

export default Single;