import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../apis/axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";



function Profile() {

  const { auth } = useAuth();
  const userId = auth.userId;
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [address, setAddress] = useState()
  const [telephone, setTelephone] = useState()
  // if(!auth.userId){
  // }

    async function handleUpdateProfile(event){
        event.preventDefault();

        console.log(event.target.elements.email);

        const inputValues = {
          firstName: event.target.elements.firstName.value,
          lastName: event.target.elements.lastName.value,
          email: event.target.elements.email.value,
          telephone: event.target.elements.telephone.value,
          address: event.target.elements.address.value,
        }

        try{
            const response = await axios.put("/users/"+userId, 
            JSON.stringify(inputValues), 
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });

            if (response.status === 200){
              window.location.reload(false);
            }

            console.log(response.data);

        }catch(err){
            console.log(err)
        }
    }

    async function getUser(){

        try{
            const response = await axios.get("/users/"+userId);
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setEmail(response.data.email);
            setTelephone(response.data.telephone);
            setAddress(response.data.address);

            console.log(response.data);


        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
      getUser();
    });

    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div className=" px-5 pt-5">
            <br /><br />
            <h2 className="pt-5 pb-5 text-white">My Account</h2>
          </div>
        </div>

        <div className="container pt-5 px-5 ">
			      <div className="row p-5">
                <div className="col-md-9 p-5 bg-grey rounded">
                    <h3 className="text-dark">My Account</h3>
                    <form onSubmit={handleUpdateProfile} >
                        <div className="row p-3">
                            <div className="col-md-4">
                                <div className="">
                                    <label className="text-dark fw-bold py-2">First Name: <span className="text-danger">*</span></label>
                                    <input type="text" name="firstName" className="form-control" defaultValue={firstName} required />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="">
                                    <label className="text-dark fw-bold py-2">Last Name: <span className="text-danger">*</span></label>
                                    <input type="text" name="lastName" className="form-control" defaultValue={lastName} required />
                                </div>
                            </div>
                            <div className="col-md-4 mt-auto">
                                
                            </div>
                        </div>
                        <div className="row p-3">
                            <div className="col-md-4">
                                <div className="">
                                    <label className="text-dark fw-bold py-2">Email: <span className="text-danger">*</span></label>
                                    <input type="text" name="email" className="form-control" defaultValue={email} required  disabled/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="">
                                    <label className="text-dark fw-bold py-2">Phone Number: <span className="text-danger">*</span></label>
                                    <input type="text" name="telephone" className="form-control" defaultValue={telephone} required />
                                </div>
                            </div>
                            <div className="col-md-4 mt-auto">
                               
                            </div>
                        </div>
                        <div className="row p-3">
                            <div className="col-md-8">
                                <div className="">
                                    <label className="text-dark fw-bold py-2">Address: <span className="text-danger">*</span></label>
                                    <input type="text" name="address" className="form-control" defaultValue={address} required />
                                </div>
                            </div>
                            
                            <div className="col-md-4 mt-auto">
                                
                            </div>
                        </div>
                        <div className="row p-3">
                            
                            <div className="col-md-4 mt-auto">
                                <button className="btn btn-danger px-5 rounded-pill">Update</button>
                            </div>
                        </div>
                    </form>

                </div>
                <div className="col-md-6"></div>
            </div>
			      
		    </div>

        <Footer />
      </>
      
    )
}
  
export default Profile;