import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from '../apis/axios';
import Footer from '../components/Footer'
import Header from '../components/Header'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "react-image-lightbox/style.css";
import useAuth from '../hooks/useAuth';
import ReactSelect from 'react-select';

function Search() {

  const location = useLocation();
  const [searchValues, setSearchValues] = useState(location?.state);

  const [allCategories, setAllCategories] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hourlyArrival, setHourlyArrival] = useState(null);

  const [locationD, setLocationD] = useState(null);
  const [propertyTypeD, setPropertyTypeD] = useState(null);


  const [timeHr, setTimeHr] = useState("0:00");
  const [hoursHr, setHoursHr] = useState("3");
  const [locationHr, setLocationHr] = useState(null);
  const [propertyTypeHr, setPropertyTypeHr] = useState(null);
  const [allRooms, setAllRooms] = useState([]);
  const [payload, setPayload] = useState({});
  
  async function getAllCategories(){

      try{
          const categories = await axios.get("/categories");

          if (categories.status === 200) {
              const categoriesObj = categories.data.map((item) => {
                  return {value: item.id, label:  item.name}
              });
              setAllCategories(categoriesObj);
          }
          
      }catch(error){
          console.log(error);
      }

  } 
    
  async function getAllStates(){

      try{
          const states = await axios.get("/states");

          if (states.status === 200) {
              const statesObj = states.data.map((item) => {
                  return {value: item.id, label:  item.name}
              });
              setAllStates(statesObj);
          }
          
      }catch(error){
          console.log(error);
      }

  } 
    
  useEffect(() => {
      getAllCategories();
      getAllStates();

      if(searchValues){
        // console.log(searchValues);
        handleSearchFromHome(searchValues)
      }
  }, []);


  async function handleSearch(event){
    event.preventDefault();

    setSearchValues(null);
    // console.log(searchValues);

    if (event.target.elements?.submitBtnD)
    {
      // console.log("Day Search");

      // setLocationD()/
      

      if ((locationD !== null) && (propertyTypeD !== null) && (startDate !== null) && (endDate !== null)){
        try{

          const start = new Date(startDate);
          const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
          const end = new Date(endDate);
          const endD = new Date(end.setDate(end.getDate()+1)).toISOString().split("T")[0]; 

          const criteria = {
            categoryId : propertyTypeD,
            stateId: locationD,
            start: startD,
            end: endD
          };

          setPayload(criteria);
          // console.log(criteria);

          const response = await axios.post("/rooms/search",
          JSON.stringify(criteria), 
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          });
          // console.log("Here ", response.data);

          const sample = response.data.reduce((group, item) => {
            const { propertyId } = item;
            group[propertyId] = group[propertyId] ?? [];
            group[propertyId].push(item);
            return group;
          }, {});
          
          // console.log("Grouped: ", sample);

          const uniqueProperty = [...new Map(response.data.map(item =>
          {
            item['rooms'] = sample[item.propertyId];
            return [item['propertyId'], item];
          }
            )).values()];

          // console.log('Unique: ',uniqueProperty);
 
          setAllRooms(uniqueProperty);
          

          const roomWithImages = uniqueProperty.map(async(item) => {
            const roomImages = await axios.post("/properties/images",
            JSON.stringify({ propertyId: parseInt(item.propertyId)}), 
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
            });

            if(roomImages.data.length > 0){
              const imageUrls = roomImages.data.map((innerItem) => {
                if(innerItem?.url){
                  return innerItem.url;
                }
              });
              return (imageUrls[0]);
            }
          });

          const roomImageUrl = await Promise.all(roomWithImages);
          roomImageUrl.map((item, index) => {
            const roomImg = document.getElementById('roomImg'+index);
            if(item){
              roomImg.style.backgroundImage = `url(${item}nth/0/)`;
            }
          });
        }catch(err){
          
        }
      }

      console.log(startDate, " - ", endDate);
      
    }
    if(event.target.elements?.submitBtnHr) {

      // console.log("Hourly Search");

      // setLocationD()/
      

      if ((locationHr !== null) && (propertyTypeHr !== null) && (hourlyArrival !== null) ){
        const start = new Date(hourlyArrival);
        const startD = new Date(start.setDate(start.getDate()+1)).toISOString().split("T")[0]; 
        setHoursHr(document.getElementById('hoursInput').value);
        setTimeHr(document.getElementById('timeInput').value);
        try{

          const criteria = {
            categoryId : propertyTypeHr,
            stateId: locationHr,
            start: startD,
            end: startD,
            time: timeHr,
            hours: hoursHr,
          };

          console.log(criteria)

          setPayload(criteria);

          

          const response = await axios.post("/rooms/search",
          JSON.stringify(criteria), 
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          });

          const sample = response.data.reduce((group, item) => {
            const { propertyId } = item;
            group[propertyId] = group[propertyId] ?? [];
            group[propertyId].push(item);
            return group;
          }, {});

          console.log("Grouped: ", sample);

          const uniqueProperty = [...new Map(response.data.map(item =>
          {
            item['rooms'] = sample[item.propertyId];
            return [item['propertyId'], item];
          }
            )).values()];

          console.log('Unique: ',uniqueProperty);

          // const sample = response.data.groupBy((item) => {
          //   return item.propertyId;
          // });
 
          setAllRooms(uniqueProperty);



          // setAllRooms(response.data);
          const roomWithImages = uniqueProperty.map(async(item) => {
            const roomImages = await axios.post("/properties/images",
            JSON.stringify({ propertyId: parseInt(item.propertyId)}), 
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
            });

            if(roomImages.data.length > 0){
              const imageUrls = roomImages.data.map((innerItem) => {
                if(innerItem?.url){
                  return innerItem.url;
                }
              });
              return (imageUrls[0]);
            }
          });

          const roomImageUrl = await Promise.all(roomWithImages);
          roomImageUrl.map((item, index) => {
            const roomImg = document.getElementById('roomImg'+index);
            if(item){
              roomImg.style.backgroundImage = `url(${item}nth/0/)`;
            }
          });
          console.log("Here ", response.data);
        }catch(err){
          console.log(err);
        }
        console.log(startD, " - ", startD);
      }   
    }

  }
  
  async function handleSearchFromHome(criteria){

    setPayload(criteria);

    try {

      const response = await axios.post("/rooms/search",
        JSON.stringify(criteria), 
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

        const sample = response.data.reduce((group, item) => {
          const { propertyId } = item;
          group[propertyId] = group[propertyId] ?? [];
          group[propertyId].push(item);
          return group;
        }, {});
        
        console.log("Grouped: ", sample);

        const uniqueProperty = [...new Map(response.data.map(item =>
        {
          item['rooms'] = sample[item.propertyId];
          return [item['propertyId'], item];
        }
          )).values()];

        console.log('Unique: ',uniqueProperty);

        setAllRooms(uniqueProperty);

      // setAllRooms(response.data);

      const roomWithImages = uniqueProperty.map(async(item) => {
        const roomImages = await axios.post("/properties/images",
        JSON.stringify({ propertyId: parseInt(item.propertyId)}), 
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

        if(roomImages.data.length > 0){
          const imageUrls = roomImages.data.map((innerItem) => {
            if(innerItem?.url){
              return innerItem.url;
            }
          });
          return (imageUrls[0]);
        }
      });

      const roomImageUrl = await Promise.all(roomWithImages);
      roomImageUrl.map((item, index) => {
        const roomImg = document.getElementById('roomImg'+index);
        if(item){
          roomImg.style.backgroundImage = `url(${item}nth/0/)`;
        }
      });

    } catch(err) {
      console.log(err);
    }
      
  }

  return (
    <>
        <Header />

        <div className="py-5 px-2">

          <div className="container toggle-section px-5 mt-5">

              <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active btn px-5 py-2" id="nav-daily-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Daily</button>
                    <button className="nav-link btn px-5" id="nav-hourly-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Hourly</button>
                  </div>
              </nav>

              <div className="tab-content bg-grey p-4" id="nav-tabContent">
                
                <div className="tab-pane fade " id="nav-home" role="tabpanel" aria-labelledby="nav-hourly-tab">
                  
                  <form onSubmit={handleSearch}>
                      <div className="row">
                          <div className="col-md-3 pb-3">
                            <label className="text-danger fw-bold">DESTINATIONS</label>
                            <ReactSelect name="location" options={allStates} onChange={(e) => { setLocationHr(e.value) }} placeholder="Destination..." className="mt-auto pt-2" required />
                          </div>
                          <div className="col pb-3">
                            <label className="text-danger fw-bold ">ACCOMODATION</label>  
                            <ReactSelect name="propertyType" options={allCategories} onChange={(e) => { setPropertyTypeHr(e.value) }} placeholder="Accomodation..." className="mt-auto pt-2" required />
                          </div>
                          <div className="col pb-3">
                            <label className="text-danger fw-bold">CHECK IN</label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                className="form-control mt-2"
                                onChange={(update) => { 
                                    console.log(update);
                                    setHourlyArrival(update);
                                }}
                                minDate={new Date()}
                                selected={hourlyArrival}
                                placeholderText={"yyyy-mm-dd"}
                            />
                          </div>
                          <div className="col-md-1 pb-3">
                              <label className="text-danger fw-bold">HOURS</label>
                                  <select name="" id="hoursInput" defaultValue={"3"}  onChange={(e) => { console.log(e); setHoursHr(e.target.value)}}  className="form-select mt-2">
                                      <option value="3">3</option>
                                      <option value="6">6</option>
                                      <option value="9">9</option>
                                      <option value="12">12</option>
                                  </select>
                              
                          </div>
                          <div className="col-md-2 pb-3">
                            <label className="text-danger fw-bold">TIME</label>
                            <select name="" id="timeInput" defaultValue={"0:00"} onChange={(e) => { console.log(e); setTimeHr(e.target.value)}} className="form-select mt-2">
                            {
                                    (hoursHr == "12")
                                    ? (
                                    <>
                                      <option value="0:00">0:00 am</option>
                                      <option value="1:00">1:00 am</option>
                                      <option value="2:00">2:00 am</option>
                                      <option value="3:00">3:00 am</option>
                                      <option value="4:00">4:00 am</option>
                                      <option value="5:00">5:00 am</option>
                                      <option value="6:00">6:00 am</option>
                                      <option value="7:00">7:00 am</option>
                                      <option value="8:00">8:00 am</option>
                                      <option value="9:00">9:00 am</option>
                                      <option value="10:00">10:00 am</option>
                                      <option value="11:00">11:00 am</option>
                                      <option value="12:00">12:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "9")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "6")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                    </>
                                    ): (<></>)
                                }
                                {
                                    (hoursHr == "3")
                                    ? (
                                    <>
                                        <option value="0:00">0:00 am</option>
                                        <option value="1:00">1:00 am</option>
                                        <option value="2:00">2:00 am</option>
                                        <option value="3:00">3:00 am</option>
                                        <option value="4:00">4:00 am</option>
                                        <option value="5:00">5:00 am</option>
                                        <option value="6:00">6:00 am</option>
                                        <option value="7:00">7:00 am</option>
                                        <option value="8:00">8:00 am</option>
                                        <option value="9:00">9:00 am</option>
                                        <option value="10:00">10:00 am</option>
                                        <option value="11:00">11:00 am</option>
                                        <option value="12:00">12:00 pm</option>
                                        <option value="13:00">13:00 pm</option>
                                        <option value="14:00 ">14:00 pm</option>
                                        <option value="15:00">15:00 pm</option>
                                        <option value="16:00">16:00 pm</option>
                                        <option value="17:00">17:00 pm</option>
                                        <option value="18:00">18:00 pm</option>
                                        <option value="19:00">19:00 pm</option>
                                        <option value="20:00">20:00 pm</option>
                                        <option value="21:00">21:00 pm</option>
                                    </>
                                    ): (<></>)
                                } 
                            </select>
                          </div>
                          
                          <div className="col my-auto" align="center" >
                            <button type="submit"  name='submitBtnHr' className="btn btn-danger px-5 rounded-pill"><span className="fw-bolder fs-6">SEARCH</span></button>
                          </div>
                      </div>
                  </form>
                
                </div>

                <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-daily-tab">
                    
                  <form onSubmit={handleSearch}>

                    <div className="row">

                      <div className="col-md-3 pb-3">
                        <label className="text-danger fw-bold">DESTINATIONS</label>
                        <ReactSelect name="locationD" id='locationD' options={allStates} onChange={(e) => { setLocationD(e.value) }} placeholder="Destination..." className="mt-auto pt-2" required />
                      </div>

                      <div className="col-md-2 pb-3">
                        <label className="text-danger fw-bold ">ACCOMODATION</label>  
                        <ReactSelect name="propertyTypeD" id='propertyTypeD' options={allCategories} onChange={(e) => { setPropertyTypeD(e.value) }} placeholder="Accomodation..." className="mt-auto pt-2" required />
                        </div>

                        <div className="col-md-4 pb-3">
                        <div className="row">
                            <div className="col">
                                <label className="text-danger fw-bold">CHECK IN</label>
                                <DatePicker
                                    selected={startDate}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control mt-2"
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={new Date()}
                                    placeholderText={"YYYY-MM-DD"}
                                />
                            </div>

                            <div className="col">
                                <label className="text-danger fw-bold">CHECK OUT</label>
                                <DatePicker
                                    selected={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control mt-2"
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText={"YYYY-MM-DD"}
                                />
                            </div>
                        </div>
                      
                        {/* <DatePicker
                            dateFormat="yyyy-MM-dd"
                            className="form-control mt-2"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => { 
                                setDateRange(update);
                                console.log(update);
                            }}
                            minDate={new Date()}
                            placeholderText={"yyyy-mm-dd - yyyy-mm-dd"}
                        /> */}
                      </div>
                        
                      <div className="col my-auto" align="center" >
                        <button type="submit" name='submitBtnD'  className="btn btn-danger px-5 rounded-pill"><span className="fw-bolder fs-6">SEARCH</span></button>
                      </div>

                    </div>

                  </form>
                </div>
            </div>
          </div>

          <div className="row px-5">

            <div className="col-md-4 px-5 py-4">

              <div className="side-floater rounded 	d-none d-sm-block" >
                <br /> <br />
              </div>
            </div>

            <div className="col-md-8 py-4 px-5">

              {
                (allRooms.length > 0)
                  ?(
                    allRooms.map((room, index) => { 
                      return (
                        <div className="row my-4 border border-opacity-25 rounded" key={index}>
                        <div className="col-md-4 img-content rounded-start" id={'roomImg'+index} style={{background: "url(/images/default.png)", backgroundSize: "cover", minHeight: 180}} ></div>

                        <div className="col-md-8 p-4 shadow-sm room-content">
                          <div className="row">
                            <div className="col-md-7 rounded-end">
                              <p className="h5 text-danger fw-bold">
                                { room?.Property?.name }
                                <br />
                                {/* <span className="text-dark fw-normal fs-6  pb-2">{ room.Property.address } </span> */}
                              </p>
                            </div>
                            
                            <div className="col-md-5">
                            {/* <div className="d-flex">
                              <div className="col text-danger"><b>Daily: </b></div>
                              <div className="col text-danger"><b>Hourly: </b></div>
                            </div>
                            <div className="d-flex">
                              <div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.price}</div>
                              <div className="col text-dark"><span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div>
                            </div> */}
                            {/* <div className="text-danger fw-normal pt-1 h6" align=""><b>Daily: </b><span className="h6 text-secondary">₦</span>{room.price} - <b>Hourly: </b> <span className="h6 text-secondary">₦</span>{room.pricePerHour}/hr</div> */}
                            </div>
                          </div>

                          {/* <div class="col-md-8 text-dark my-auto" align="right"><i class="fa fa-map-marker fa-lg text-danger"></i>&nbsp;&nbsp; 120 Adeniyi Jones street, Ikeja, Lagos</div> */}

                          {/* <p className="h6 text-dark">Room Type: { room.roomType }</p> */}
                          <div className="text-dark fw-normal pt-2"><i class="fa fa-map-marker fa-lg text-danger"></i> &nbsp; { room.Property.address } </div>
                          <br />
                          {/* <div className="text-dark fw-normal pt-2">{ room.Property.description.slice(0,100) } </div> */}
                          <div className="row ">
                            <div className="col-md-3 row">
                            </div>
                            <div className="col" align='center'>
                            <span className='text-danger fs-6 d-none' id={'status'+index}>Not Available!</span>
                            </div>

                            <div className="col" align="right">
                            {/* <input type="checkbox" className="btn-check"  id={"btn-check"+index} autoComplete="off" /> */}
                            <Link 
                              to={"/property/"+room.propertyId+"/room/"+room.id} 
                              state={{...payload, priceHr: room.pricePerHour, rooms: room.rooms} } 
                              className="btn btn-danger btn-sm px-5 py-1 rounded text-uppercase"
                            > view rooms
                            </Link>
                              
                            </div>
                          </div>

                        </div>
                      </div>
                      
                      )
                      
                    })
                  )
                  :(
                    <div className="bg-grey rounded p-5">
                      <h4 className="fw-normal text-center">No Results Available.</h4>
                    </div>
                  )
              }

            </div>

          </div>
        </div>


        <Footer />
    </>
    
  )
}

export default Search;