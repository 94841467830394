/* eslint-disable eqeqeq */
import { useEffect } from "react";
import { useState } from "react";
import axios from "../apis/axios";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { PaystackButton } from 'react-paystack'
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {
    useFlutterwave,
    FlutterWaveButton,
    closePaymentModal
  } from "flutterwave-react-v3";



function Payment() {

    const url = "/bookings";
    const location = useLocation();
    const results = location.state;
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [paymentId, setPaymentId] = useState(null); 
    const [paystackKey, setPaystackKey] = useState(null); 
    const [flutterKey, setFlutterKey] = useState(null); 
    const navigate = useNavigate();

   

    //console.log(results);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const selectedPayment = 0;

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [telephone, setTelephone] = useState("");
    const [paymentResult, setPaymentResult] = useState(null);

    const [submit, setSubmit] = useState("Complete");

    const orderId = Date.now();

    const flutterConfig = {
        public_key: flutterKey,
        tx_ref: Date.now(),
        currency: "NGN",
        amount: results.total,
        payment_options: "card,mobilemoney,USSD",
        customer: {
          email: email,
          phonenumber: telephone,
          name: firstName +" "+ lastName
        },
    
        customizations: {
          title: "HB Booking",
          description: "Booking Payment",
          logo: "https://hotbym.com/hotbym-logo.png"
        }
    };

    const fwConfig = {
        ...flutterConfig,
        text: 'Pay Now!',
        callback: (response) => {
           console.log(response);
          closePaymentModal() // this will close the modal programmatically
        },
        onClose: () => {},
    };
    
    const componentProps = {
        email: email,
        amount: results.total+"00",
        metadata: {
          name: firstName +" "+ lastName,
          phone: telephone,
          orderId: orderId,
        },
        publicKey: paystackKey,
        text: submit,
        onSuccess: (data) => {
            // handleBooking(data)
            setPaymentResult(data);
        }
      }

    const getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
    
    async function getPaymentMethods(){
        try{
            const allPaymentMethods = await axios.get("/payments");
            if (allPaymentMethods.status === 200){
                setPaymentMethods(allPaymentMethods.data);
                if(paymentId == null){

                    const activePM = allPaymentMethods.data.map((item, index) =>{
                        if(item.status == "active"){
                            return item;
                        }
                    });

                    activePM.map((item, index) => { 
                        if(index == 0) setPaymentId(item.id);
                        return "";
                    })

                }
                console.log(paymentId);
                setPaystackKey((allPaymentMethods.data.filter((item) => { return item.title.toLowerCase() == "paystack" }))[0].publicKey)
                setFlutterKey((allPaymentMethods.data.filter((item) => { return item.title.toLowerCase() == "flutterwave" }))[0].publicKey)
            }
        }catch(err){
            console.log(err);
        }
    }

    async function handleBooking(paymentData){

        document.getElementsByClassName("btnSubmit").disabled = true
        let reference = "";
        let status = "pending";
        if (paymentData != "offline"){
            reference =  paymentData.reference;
            status = "completed";
        }
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>);

        

        let time = results.start.split(" ")[1];
        let sd = new Date(results.start);
        let ed = new Date(results.end);
        let startArray = (new Date (sd.setDate(sd.getDate() + 1))).toISOString().split("T");
        let endArray = (new Date (ed.setDate(sd.getDate() + 1))).toISOString().split("T");
        let depTime = results.end.split(" ")[1];
        let startDate = startArray[0];
        let endDate = endArray[0];
        let bookingType = "daily";
        let timeIn = null;
        let timeOut = null;
        
        
        if(time != depTime){
            sd = new Date(results.start.split(" ")[0]);
            startArray = new Date(sd).toISOString().split("T");
            startDate = startArray[0];
            endDate = startArray[0];
            bookingType = "hourly";
            timeIn = time;
            timeOut = depTime;
            // console.log(startDate, " - ", endDate);
        }
        
        let staticInputValues = {
            userId: auth.userId, 
            organisationId: null,
            paymentId: paymentId,
            reference: reference,
            type: "Personal",
            status: status,
            arrivalDate: startDate,
            departureDate: endDate,
        };
        

        const bookingProcessResult = results.rooms.map(async (item) => {
            const inputValues = {
                ...staticInputValues,
                roomId: item.roomId,
                orderId: orderId,
                price: item.price,
                commission: 0,
                markup: 0,
                totalAmount: (parseInt(item.total)),
                quantity: item.quantity,
            }
            console.log(inputValues);

            try{

                const list = getDaysArray(new Date(inputValues.arrivalDate), new Date(inputValues.departureDate));
                
                const response = await axios.post(url, 
                    JSON.stringify(inputValues), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                    
                
                
                if (response.status === 200){

                    if( bookingType == "hourly"){

                        const dailyInput = {
                            orderId: orderId,
                            roomId: inputValues.roomId,
                            dateTime: startArray[0],
                            quantity: inputValues.quantity,
                            dateOnly: startArray[0],
                            type: bookingType,
                            timeIn,
                            timeOut
                        };
                        console.log("hourly: "+ dailyInput);
                        
                        // console.log("With Time ",item);
                        // console.log("With out Time ", startArray[0]);
                        // console.log("With out Time2 ", startDate);

                        try{    
                            await axios.post("/bookings/daily", 
                                JSON.stringify(dailyInput), 
                                {
                                    headers: { 'Content-Type': 'application/json' },
                                    withCredentials: true
                            });
                        }catch(error){
                            // console.log(error);
                        }
                
                    }else {

                        list.map(async (item) => {
    
                            const dailyInput = {
                                orderId: orderId,
                                roomId: inputValues.roomId,
                                dateTime: item.toISOString(),
                                quantity: inputValues.quantity,
                                dateOnly: startArray[0],
                                type: bookingType,
                                timeIn,
                                timeOut
                            };
                            
                            console.log("daily: "+ dailyInput);

                            try{    
                                await axios.post("/bookings/daily", 
                                    JSON.stringify(dailyInput), 
                                    {
                                        headers: { 'Content-Type': 'application/json' },
                                        withCredentials: true
                                });
                            }catch(error){
                                console.log(error);
                            }
                        });
                    }

                    // update transactions
                    const transactionInput = {
                        userId: auth.userId,
                        orderId: orderId,
                        paymentId: inputValues.paymentId,
                        reference: inputValues.reference,
                        status: inputValues.status,
                        amount: inputValues.totalAmount,
                    }
                    await axios.post("/transactions", 
                        JSON.stringify(transactionInput), 
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                    });

                    return "success";
                }else{
                    // console.log(response)
                }

            }catch(error){
                console.log(error);
                return "error";
            }

        });

        // event.target.elements.submitBtn.disabled = false;
        
        const result = await bookingProcessResult[0];
        if(result == "success"){
            setError("");
            setSuccess("Booking created successfully.");
            setTimeout(() => {
                navigate('/');
            }, 3000)
        }else {
            setSubmit("Complete");
            document.getElementsByClassName("btnSubmit").disabled = false;
            setSuccess("");
            setError("An Error occured during your booking.");
        }

    }

    useEffect(() => { 
        getPaymentMethods(); 

        if(paymentResult != null){
            handleBooking(paymentResult)
        }   

    }, [paymentResult]);

    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div className=" px-5 pt-5">
            <br /><br />
            <h2 className="pt-5 pb-5 text-white">Payment</h2>
          </div>
        </div>

        <div className="container pt-5  ">
			<div className="row gx-5 pt-5 px-5 ">
				
				<div className="col-md-8 bg-grey">

                    <div className="container p-5">
                                <h3 className="text-dark">Select Payment Method</h3>
                        <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8" align="center">
                                <span className="text-danger text-center small"><b>{error}</b></span>
                                <span className="text-success text-center small"><b>{success}</b></span>
                            </div>
                            <div className="col-md-2" align="right">
                                <span className="text-dark fs-6 fw-bold ">₦{results.total}.00</span>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-md-2 nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                {
                                    paymentMethods.map((item, index) => {
                                        if(item.status == "active"){
                                            let activeStatus = "active";
                                            if(index != selectedPayment) activeStatus = "";
                                            if(paymentId == null){ setPaymentId(item.id); }
                                            return (
                                                <button key={index} onClick={()=> { setPaymentId(item.id)}} className={"nav-link "+activeStatus} id={"v-pills-btn-tab"+index} data-bs-toggle="pill" data-bs-target={"#v-pills-tab"+index} type="button" role="tab" aria-controls={"v-pills-home"+index} aria-selected="true">
                                                    {item.title}
                                                </button>
                                            );
                                        }
                                    })
                                }    
                            </div>

                            <div className="col-md-10 tab-content" id="v-pills-tabContent">
                                    {
                                        paymentMethods.map((item, index) => {
                                            if(item.status == "active"){
                                                //if(index === 0) setPaymentId(item.id);
                                                let activeStatus = "show active";
                                                if(index != selectedPayment) activeStatus = "";
                                                if (item.type == "offline"){
                                                    return (
                                                        <div className={"tab-pane fade "+activeStatus} key={index} id={"v-pills-tab"+index} role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                                                <div className="row">
                                                                    <div className="col-md-12 px-5 ">
                                                                        <table className="table bg-white table-striped table-bordered table-compact">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Bank Name: </th>
                                                                                    <td>{item.bankName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Account Name: </th>
                                                                                    <td>{item.accountName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Account Number: </th>
                                                                                    <td colSpan="2">{item.accountNumber}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Sort: </th>
                                                                                    <td colSpan="2">{item.sort}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Swift: </th>
                                                                                    <td colSpan="2">{item.swift}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Iban: </th>
                                                                                    <td colSpan="2">{item.iban}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <p className="text-dark" >Make sure to add <b>{"HB-"+orderId}</b> to your payment details.</p>
                                                                        <button className="btn btn-dark px-5 btnSubmit" onClick={(e) => { handleBooking("offline")}}>{submit}</button>
                                                                    </div>
                                                                    
                                                                </div>
                                                        </div>
                                                    );
                                                }else{
                                                    if(item.title == "Paystack"){
                                                        return (
                                                            <div key={index} className={"tab-pane fade px-5 "+activeStatus} id={"v-pills-tab"+index} role="tabpanel" aria-labelledby={"v-pills-tab"+index} tabIndex="0">

                                                                <div className="row py-2">
                                                                    <div className="col-md-6">
                                                                        <label>First Name: <span className="text-danger"></span></label>
                                                                        <input type="text" className="form-control"  name="firstName" placeholder="First Name" onChange={(e) => { setFirstName(e.target.value) }} required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Last Name: <span className="text-danger"></span></label>
                                                                        <input type="text" className="form-control"  name="lastName" placeholder="Last Name" onChange={(e) => { setLastName(e.target.value) }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-md-12">
                                                                        <label>Email: <span className="text-danger">*</span></label>
                                                                        <input type="email" className="form-control"  name="email" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-md-12">
                                                                        <label>Telephone: <span className="text-danger"></span></label>
                                                                        <input type="tel" className="form-control"  name="telephone" placeholder="Telephone" onChange={(e) => { setTelephone(e.target.value) }} />
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <PaystackButton {...componentProps} className="btn btn-dark  px-5 btnSubmit"/>
                                                            </div>
                                                        );
                                                    
                                                    }else if(item.title == "Flutterwave"){
                                                        return (
                                                            <div key={index} className={"tab-pane fade px-5 "+activeStatus} id={"v-pills-tab"+index} role="tabpanel" aria-labelledby={"v-pills-tab"+index} tabIndex="0">

                                                                <div className="row py-2">
                                                                    <div className="col-md-6">
                                                                        <label>First Name: <span className="text-danger"></span></label>
                                                                        <input type="text" className="form-control"  name="firstName" placeholder="First Name" onChange={(e) => { setFirstName(e.target.value) }} required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Last Name: <span className="text-danger"></span></label>
                                                                        <input type="text" className="form-control"  name="lastName" placeholder="Last Name" onChange={(e) => { setLastName(e.target.value) }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-md-12">
                                                                        <label>Email: <span className="text-danger">*</span></label>
                                                                        <input type="email" className="form-control"  name="email" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} required />
                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-md-12">
                                                                        <label>Telephone: <span className="text-danger"></span></label>
                                                                        <input type="tel" className="form-control"  name="telephone" placeholder="Telephone" onChange={(e) => { setTelephone(e.target.value) }} />
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                {/* <PaystackButton {...componentProps} className="btn btn-dark  px-5 btnSubmit"/> */}
                                                                <FlutterWaveButton {...fwConfig} className="btn btn-dark  px-5 btnSubmit" />
                                                            </div>
                                                        );
                                                    }
                                                    else{
                                                        return (
                                                            <div className={"tab-pane fade "+activeStatus} key={index} id={"v-pills-tab"+index} role="tabpanel" aria-labelledby={"v-pills-tab"+index} tabIndex="0">{"#"+item.title}</div>
                                                        );
                                                    }
                                                }
                                            }
                                        })
                                    }
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </>
      
    )
}
  
export default Payment;