import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import ScrollToTop from './components/ScrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    {/* <React.StrictMode> */}
      <AuthProvider>
        <App />
      </AuthProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

