import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function Footer() {
    const { auth } = useAuth();
  return (
    <>
        <footer className="footer">
            <div className="container">
                <div className="row">

                    <div className="col-md-3 py-4 my-auto">
                        <div className="logo footer-width mobile-align"><Link to="/"><img src={`${window.location.origin}/images/hotbym-logo.png`} alt="HOTBYM"/></Link></div>
                    </div>
                    
                    <div className="col mobile-align">
                        <div className="fs-5 text-uppercase text-danger">About Us</div>
                        <ul className="p-0 footer-list">
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/about"}>About us</Link></li>
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/privacy"}>Privacy Policy</Link></li>
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/cookies"}>Cookies Policy</Link></li>
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/terms"}>Terms &amp; Conditions</Link></li>
                            
                        </ul>
                    </div>
                    
                    <div className="col mobile-align">
                        <div className="fs-5 text-uppercase text-danger">Account</div>
                        <ul className="footer-list p-0">
                            {
								auth.userId
									?(<Link className='text-white pt-2' to={"/profile"}>My Account</Link>)
									// ?(<Link to="/payment" state={{ results: "results" }} className="btn btn-danger btn-lg px-5 py-2 mt-3 rounded text-uppercase"><span className="fw-bolder fs-6">complete booking</span></Link>)
									:(<Link className='text-white pt-2' data-bs-toggle="modal" data-bs-target="#login">My Account</Link>)
							}
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/track/bookings"}>Verify Booking</Link></li>
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/requests"}>Corporate Booking</Link></li>
                        </ul>
                    </div>
                    
                    <div className="col-md-4 mobile-align">
                        <div className="fs-5 text-uppercase text-danger">contact info</div>
                        <ul className="footer-list p-0">
                            <li className="fw-normal pt-2 footer-text-color"><Link to={"/contact"}>Contact Us</Link></li>
                            <li className="fw-normal pt-2 footer-text-color">Email: <a href="mailto:info@hotbym.com">info@hotbym.com</a></li>
                            <li className="fw-normal pt-2 footer-text-color">Tel.: <a href="tel:+2349099990972">+234 90 999 909 72</a></li>
                            <li className="fw-normal pt-2 footer-text-color">Address: <span className='text-white'> 3-9 Olu Koleosho Str, Off Medical Rd, Ikj, Lagos. </span> </li>
                        </ul>
                    </div>
                </div>
                    
            </div>
        </footer>

        <div className="copyright d-none d-sm-block">
            <div className="container">
                <div className="copyright_content align-items-center">
                    <div className='text-white'>
                        Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved.
                    </div>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default Footer