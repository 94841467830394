import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

function CookiePolicy() {
    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">Cookie Policy</h2>
            </div>
        </div>

        <div className="container p-5"> 
            <p className="fs-6">
                <b className="text-dark">1. INFORMATION ABOUT OUR USE OF COOKIES</b> <br />
                <p className="text-dark"><b>1.1</b> A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer, if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
                <p className="text-dark"><b>1.2</b> <Link to={"/"}>WWW.HOTBYM.COM</Link> uses cookies to distinguish you from other users of our website. This help className="text-dark"s us to imp className="text-dark"rove our website and provide you with a good experience when you browse.</p>
                <p className="text-dark"><b>1.3</b> By continuing to browse our website, you are agreeing to our use of cookies.</p>
            </p>

            <p className="fs-6">
                <b className="text-dark">2. WE USE THE FOLLOWING COOKIES</b> <br />
                <p className="text-dark"><b>2.1 Strictly necessary cookies</b> 
                    <p className="text-dark"><b>2.1.1</b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</p>
                </p>    
                <p className="text-dark"><b>2.2 Analytical/performance cookies</b>
                    <p className="text-dark"><b>2.2.1</b> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                </p>
                <p className="text-dark"><b>2.3 Functionality cookies</b> 
                    <p className="text-dark">
                        <b>2.3.1</b> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </p>
                </p>
                <p className="text-dark"><b>2.4 Targeting cookies</b>
                    <p className="text-dark">
                        <b>2.4.1</b> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
                    </p>
                </p>
                
            </p>

            <p className="fs-6">
                <b className="text-dark">3. THIRD PARTY COOKIES</b> <br />
                <p className="text-dark"><b>3.1</b> Please note that third parties (for example, advertising networks and providers of external services, such as web traffic analysis services) may also use cookies over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
            </p>

            <p className="fs-6">
                <b className="text-dark">4. YOUR OPTION TO BLOCK COOKIES</b> <br />
                <p className="text-dark"><b>4.1</b> You block cookies by activating the setting on your browser that allows you to refuse the setting of all, or some cookies. However, if you use your browser settings to block all cookies (including essential cookies), you may not be able to access all, or parts of our website. </p>
            </p>
        </div>

        <Footer />
      </>
    )
  }
  
  export default CookiePolicy;