import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function HotelListing() {
  return (
    <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>

            <div className=" px-5 pt-5">
              <br />
              <br />
              <h2 className="pt-5 pb-5 text-white">Hotel Listing</h2>
            </div>

        </div>

        <div className="container p-5">

        </div>
        <Footer />
    </>
  )
}

export default HotelListing