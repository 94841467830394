import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";

const Authenticate = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    // console.log("here in autenticate", auth);

    useEffect(() => {
        //let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                setIsLoading(false);
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken? verifyRefreshToken() : setIsLoading(false);

        //return () => isMounted = false;
    }, [refresh, auth?.accessToken])

    // useEffect(() => {
    //     console.log(`isLoading: ${isLoading}`)
    //     console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    // }, [isLoading, refresh, auth?.accessToken])

    return (
        <>
            {isLoading
                ? <div className="spinner-border text-danger" style={{width: 50, height: 50}} role="status"></div>
                : <Outlet />
            }
        </>
    )
}

export default Authenticate