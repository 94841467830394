import { useState, useEffect } from "react";
import axios from "../apis/axios";
import Footer from "../components/Footer";
import Header from "../components/Header";



function ViewBooking() {

    const [result, setResult] = useState("No result to display at the moment.");

    async function handleGetBooking(event){
        event.preventDefault();

        const bookingId = event.target.elements.bookingId.value;

        try{
            const response = await axios.post("/bookings/order", 
            JSON.stringify({
                orderId: bookingId.split("-")[1]
            }), 
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });

            console.log(response.data);

            const displayedResult = response.data.map((item, index) => {

                return (

                    <div key={index}>
                        <p className="text-dark fs-6">
                            <b>Booking ID:</b> HB-{item.orderId}
                        <br />
                            <b>Arrival Date:</b> {item.arrivalDate}
                        <br />
                            <b>Departure Date:</b> {item.departureDate}
                        <br />
                            <b>Status:</b> {item.status}
                        <br />
                            <b>Total:</b> ₦{item.totalAmount}
                        </p>
                    </div>
                )
            });

            if (displayedResult.length > 0){
                setResult(displayedResult);
            } else {
                setResult("No result to display at the moment."); 
            }


        }catch(err){
            console.log(err)
        }
    }

    return (
      <>
        <Header />
        <div className="container-fluid bg-shade p-5" style={{background: "url(/images/pexels-cats-coming-707581.jpg)", backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div className=" px-5 pt-5">
            <br /><br />
            <h2 className="pt-5 pb-5 text-white">Confirm Booking</h2>
          </div>
        </div>

        <div className="container pt-5  ">
			<div className="row p-5">
                <div className="col-md-9 p-5 bg-grey rounded">
                    <h3 className="text-dark">Check Booking Status</h3>
                    <form onSubmit={handleGetBooking} >
                        <div className="row p-3">
                            <div className="col-md-7 col-sm-7 py-3">
                                <div className="">
                                    <label htmlFor="bookingId" className="text-dark fw-bold py-2">Booking Number: <span className="text-danger">*</span></label>
                                    <input type="text" name="bookingId" className="form-control" placeholder="e.g. HB-1234567890 " pattern="^(HB-\d{13})?$" required />
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-7 py-3 mt-auto">
                                <button className="btn btn-danger px-5 rounded-pill">Search</button>
                            </div>
                        </div>
                    </form>

                    <br />
                    <br />

                    <div className=" mx-3 p-4 bg-white rounded text-dark">
                        <p className=" fs-5">
                            {result}
                        </p>
                    </div>

                </div>
                <div className="col-md-6">

                </div>
            </div>
		</div>

        <Footer />
      </>
      
    )
}
  
export default ViewBooking;