function Banner() {
  return (
    <>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div id="slide-1" className="slide carousel-item active">
                    <div className="slide-content">
                        <p className="text-uppercase fw-bold h3 text-white display-6">Micro Stay</p>
                        <p className="text-white pt-3 fs-6">We facilitate flexible accommodations thats just right for you, book from 3 to 24 hours or opt for the regular booking schedules,<br/> either way you save and you can cancel at any time</p>
                        <br/><br/>
                    </div>
                </div>
                <div id="slide-2" className="slide carousel-item">
                    <div className="slide-content">
                    <p className="text-uppercase fw-bold h3 text-white display-6">Micro Stay</p>
                        <p className="text-white pt-3 fs-6">We facilitate flexible accommodations thats just right for you, book from 3 to 24 hours or opt for the regular booking schedules,<br/> either way you save and you can cancel at any time</p>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Banner